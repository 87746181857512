import queryString from 'query-string';
import bugsnag from 'bugsnag-js';

const isLocal = (window && window.location && window.location.href && window.location.href.includes('localhost'));

let env = 'staging';
if (isLocal) {
  env = 'development';
} else if (process.env.API_MODE === 'production') {
  env = 'production';
} else if (process.env.API_MODE === 'demo') {
  env = 'demo';
}

const bugsnagClient = bugsnag({
  apiKey: '0c7e87bca1f974fa1fac37f7aa9008da',
  releaseStage: env,
  notifyReleaseStages: ['staging', 'production']
});

export function preserveSearch(newQuery, currentSearch) {
  return queryString.stringify({ ...newQuery, ...queryString.parse(currentSearch) });
}

export const log = {
  error: (error, opts) => {
    bugsnagClient.notify(error, opts);
  },
  warn: (error, opts) => {
    bugsnagClient.notify(error, {
      ...opts,
      severity: 'warning'
    });
  },
  info: (error, opts) => {
    bugsnagClient.notify(error, {
      ...opts,
      severity: 'info'
    });
  }
};

export default {
  preserveSearch,
  log
};

