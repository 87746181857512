import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import moment from 'moment';

import FormText from '../../components/Form/FormText';
import { withApiConsumer } from '../../state/apiProvider';
import { colors } from '../../styles/variables';
import { Leg } from '../Legs';
import LocalizedSpan from '../../components/LocalizedSpan';

const getRoom = (rooms, roomId) => _find(rooms, { id: roomId });

/*
- käy huoneessa, tarkasta että moduuli on jalassa
  - kalibroi se uudelleen, make sure it blinks
- jos patteri alle 5%
- voi olla verkko-ongelma, jos toistuu, paha paikka
- gateway, paitsi jos muut jalat online
- soita akulle
*/

const getReason = (sensor) => {
  if ((!!sensor.battery || sensor.battery === 0) && sensor.battery < 20) {
    return { type: 'battery', problem: "BATTERY_PROBLEM", fix: 'BATTERY_FIX' };
  }
  if (moment(sensor.lastMessage) < moment().subtract(2, 'days')) {
    return { type: 'overflow', problem: "OVERFLOW_PROBLEM", fix: 'OVERFLOW_FIX' };
  }
  if (sensor.apiStatus === 'debug') {
    return { type: 'debug', problem: "DEBUG_PROBLEM", fix: 'DEBUG_FIX' };
  }
  if (sensor.apiStatus === 'inactive') {
    return { type: 'out-of-leg', problem: "OUTOFLEG_PROBLEM", fix: 'OUTOFLEG_FIX' };
  }
}

const OfflineMessageView = ({ rooms, message }) => {
  const room = getRoom(rooms, message.roomId);
  let reason;
  const sensor = _find(_get(room, 'sensors', []), s => (message.affectedSensors || []).includes(s.id));
  if (sensor) {
    reason = getReason(sensor);
  }
  return (
    <div style={{ padding: '1em', flex: 1 }}>
      <FormText>
        <strong><LocalizedSpan stringId="OFFLINE_TITLE" /></strong>
      </FormText>
      <FormText>
        <LocalizedSpan stringId="The leg" /> {sensor.id} <LocalizedSpan stringId="in room" /> {room.name} <LocalizedSpan stringId="has" /> <LocalizedSpan stringId={reason.problem} />.
      </FormText>
      <div style={{ padding: '2em 1em' }}>
        <Leg leg={sensor} style={{ border: '1px solid', borderColor: colors.borderColor, borderRadius: '.4em' }} />
      </div>
      <FormText>
        <strong><LocalizedSpan stringId={"OFFLINE_FIX"} /></strong>
      </FormText>
      <FormText>
        <LocalizedSpan stringId={reason.fix} />
      </FormText>
    </div>
  );
};

export default withApiConsumer(OfflineMessageView);
