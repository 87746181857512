import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/variables';
import LocalizedSpan, { isLocalized } from '../LocalizedSpan';

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  padding: .8em;

  span.field-label {
    display: block;
    margin: .4em .2em;
    color: ${colors.text2};

    .required-star {
      font-size: 1.2em;
      color: ${colors.pink};
    }
  }
`;

const FormField = ({ label, children, required = false }) => {
  let labelToUse = label;
  if (isLocalized(label)) {
    labelToUse = <LocalizedSpan stringId={label} />;
  }
  return (
    <StyledField title={`${label}${required ? ' (required)' : ''}`}>
      <span className="field-label">
        {labelToUse}
        {required && <span className="required-star">*</span>}
      </span>
      <div style={{ padding: '0.3rem' }}>
        {children}
      </div>
    </StyledField>
  );
};

export default FormField;
