import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle } from 'recompose';
import { Route, Link } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import map from 'lodash/map';

import { colors } from '../../styles/variables';
import PageHeader from '../../components/PageHeader';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ExternalLinkButton, LinkButton } from '../../components/Button';
import RoomInfo from '../RoomInfo';
import Drawer from '../../components/Drawer';
import OverView from '../Overview';

import FeedItem from './FeedItem';
import { withApiConsumer } from '../../state/apiProvider';
import LocalizedSpan from '../../components/LocalizedSpan';

import SupportContact from '../../components/SupportContact';

const Container = styled.div`
  flex: 1;
  display: flex;
  background: ${colors.containerBackground};

  .header {
    position: sticky;
    top: 0;
  }

  .content {
    flex: 1;
    padding-bottom: 10em;
  }
`;

const FeedList = styled.div`
  padding: 1em;
  max-width: 40em;
  margin: 0 auto;

  .list-item {
    margin-bottom: 1em;
  }
`;

const Empty = styled.div`
  padding: 1.4em;
  color: ${colors.text2};
  font-size: 1.6em;

  h2 {
    color: ${colors.text1};
    margin: 0;
    margin-bottom: 0.4em;
  }

  .linkButton {
    display: inline-block;
    text-transform: none;
    margin-top: 1em;
    margin-left: 0;
    font-size: 0.7em;
  }
`;

const getGroupedFeed = feed => groupBy(feed, 'roomId');

const getRoom = (rooms, roomId) =>
  find(rooms, {
    id: roomId
  });

const Feed = ({ className, feed, rooms }) => (
  <Container className={className}>
    <div className="content">
      <PageHeader className="header" titleStringId="FEED_TITLE" />
      {feed.loading ? (
        <div
          id="feed__loading"
          style={{
            padding: '1em 2em'
          }}
        >
          <LoadingIndicator />
        </div>
      ) : Object.keys(feed).length > 1 ? (
        <FeedList id="feed__list">
          {map(getGroupedFeed(feed), (messages, roomId) => (
            <FeedItem
              className="list-item"
              key={roomId}
              room={getRoom(rooms, roomId)}
              messages={messages}
              customRoomPath="/feed/room/"
            />
          ))}
        </FeedList>
      ) : (
        <Empty id="feed__empty">
          <h2><LocalizedSpan stringId="FEED_EMPTY_MESSAGE" /></h2>
          <ExternalLinkButton
            className="linkButton"
            fill
            round
            color="white"
            to="/rooms"
          >
            <LocalizedSpan stringId="FEED_SHOW_ALL_ROOMS_MESSAGE" />
          </ExternalLinkButton>
        </Empty>
      )}
      <Container>
        <div style={{ margin: '0 auto' }}>
          <SupportContact />
        </div>
      </Container>
    </div>
  </Container>
);

Feed.propTypes = {
  className: PropTypes.string
};

Feed.defaultProps = {
  className: ''
};

const enhance = compose(
  withApiConsumer,
  lifecycle({
    componentDidMount() {
      this.props.loadOverview();
      this.props.loadFeed();
    }
  })
);

export default enhance(Feed);
