import React from 'react';
import { withRouter } from 'react-router-dom';
import { withApiConsumer } from '../../state/apiProvider';
import { compose } from 'recompose';
import find from 'lodash/find';
import Legs from '../Legs';

const getRoom = (rooms, roomId) => find(rooms, { id: roomId });


const UnpairedInfo = ({ rooms }) => {
  const unpairedDevices = getRoom(rooms, '0');
  return unpairedDevices ? <Legs unpaired legs={unpairedDevices.sensors} /> : null;
};

const enchance = compose(
  withApiConsumer,
  withRouter,
);
export default enchance(UnpairedInfo);

