import React from 'react';
import _toArray from 'lodash/toArray';
import _some from 'lodash/some';
import _min from 'lodash/min';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import _flatten from 'lodash/flatten';
import _sum from 'lodash/sum';
import _filter from 'lodash/filter';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Battery, BatteryCharging } from 'react-feather';
import { withApiConsumer } from '../../state/apiProvider';
import LocalizedSpan from '../../components/LocalizedSpan';
import Section from '../../components/Section';
import colors from '../../styles/variables/colors';

export const batteryLowLimit = 20;

const someLowBattery = sensors => _some(
  sensors,
  ({ battery }) => (battery !== null) && battery < batteryLowLimit
);

const countBatteries = sensors =>
  _sum(_map(sensors, e => (e.battery > batteryLowLimit ? 0 : 2)));

const getTotalBatteryCount = rooms => 
  countBatteries(_flatten(_map(rooms, e => e.sensors)));

const minBatteryLevel = sensors => _min(_map(sensors, e => e.battery));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.tr`
  padding: 0.8rem;
  margin: 0 0.6rem;
`;

const RowHeader = styled.thead`
  position: sticky;
  top: 0;
  padding: 0.4em 1.6em;
  color: ${colors.text3};

  tr > th:first-child {
    text-align: left;
  }
  tr > th:nth-child(2) {
    text-align: center;
  }
  tr > th:last-child {
    text-align: right;
  }
`;

const RoomInfo = ({ name, sensors }) => {
  const batteryIsEmpty = minBatteryLevel(sensors) < batteryLowLimit;

  return (
    <Row>
      <td>{name}</td>
      <td>{sensors.filter(s => s.battery < batteryLowLimit).map(({ id }) => (<div>{id}</div>))}</td>
      <td
        style={{
          color: batteryIsEmpty ? colors.sensor_orange : colors.sensor_green
        }}
      >
        {batteryIsEmpty ? 'Low' : 'OK'}
      </td>
    </Row>
  );
};

const Table = styled.table`
  border-top: 1px solid ${colors.itemBorderColor};
  padding: 2rem;
  width: 100%;
`;

const RowBody = styled.tbody`
  tr > td {
    padding: 1rem 0;
    border-top: 1px solid ${colors.itemBorderColor};
  }

  tr > td:nth-child(2) {
    text-align: center;
  }
  tr > td:last-child {
    text-align: right;
  }
`;

const Header = ({ rooms }) => (
  <div
    style={{
      borderBottom: `1px solid ${colors.itemBorderColor}`
    }}
  >
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      {getTotalBatteryCount(rooms) > 0 ? (
        <div>
          <p>
            Batteries need to be changed in <b>{rooms ? rooms.length : 0}</b> rooms for <b>{getTotalBatteryCount(rooms) / 2}</b> legs.
          </p>
          <p>
            You will need a total of <b>{getTotalBatteryCount(rooms)}</b> batteries.
          </p>
          {/* <LocalizedSpan stringId=""></LocalizedSpan> */}
          {/* `There are ${rooms.length} rooms with total of ${getTotalBatteryCount(rooms)} batteries to be changed.` */}
        </div>
      ) : (
        <LocalizedSpan stringId="NO_ACTION_REQUIRED" />
      )}
    </div>
  </div>
);

const BatteryInfo = ({ rooms }) => {
  const filteredRooms = _filter(_toArray(rooms), e => e.name !== 'Unpaired');
  const { false: noChange, true: change } = _groupBy(_toArray(filteredRooms), e => someLowBattery(e.sensors));
  return (
    <Container>
      <Header rooms={change} />
      {change &&
        <Section
          initialCollapsed={false}
          title={<LocalizedSpan stringId="ROOM_NEED_TO_CHANGE_BATTERY" />}
          // icon={<BatteryCharging />}
        >
          <Table>
            <RowHeader>
              <tr>
                <th>
                  <LocalizedSpan stringId="ROOM" />
                </th>
                <th>
                  <LocalizedSpan stringId="TRAPS_NEEDING_BATTERY_CHANGE" />
                </th>
                <th>
                  <LocalizedSpan stringId="BATTERY_LEVEL" />
                </th>
              </tr>
            </RowHeader>
            <RowBody>
              {change &&
                _sortBy(change, e => [
                  e.name,
                  minBatteryLevel(e.sensors),
                  someLowBattery(e.sensors)
                ]).map(RoomInfo)}
            </RowBody>
          </Table>
        </Section>}
      {/* <Section
        initialCollapsed
        title={<LocalizedSpan stringId="ROOM_DONT_NEED_TO_CHANGE_BATTERY" />}
        // icon={<Battery />}
        >
        <Table>
          <RowHeader>
            <tr>
              <th>
                <LocalizedSpan stringId="ROOM" />
              </th>
              <th>
                <LocalizedSpan stringId="BATTERY_LEVEL" />
              </th>
              <th>
                <LocalizedSpan stringId="TRAPS_NEEDING_BATTERY_CHANGE" />
              </th>
            </tr>
          </RowHeader>
          <RowBody>
            {noChange &&
            _sortBy(noChange, e => [e.name, minBatteryLevel(e.sensors)]).map(RoomInfo)}
          </RowBody>
        </Table>
      </Section> */}
    </Container>
  );
};

export default compose(
  withApiConsumer,
  withRouter
)(BatteryInfo);
