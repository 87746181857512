import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { colors } from '../../styles/variables';
import LocalizedSpan, { isLocalized } from '../LocalizedSpan';

const Container = styled.div`
  display: grid;
  padding: .4em;
  grid-template-columns: 4em 1fr 4em;
  grid-template-rows: 4em;
  justify-items: center;
  align-items: center;
  min-width: 24em;
  /* padding: 1em; */

  ${props => !!props.onClick && css`
    cursor: pointer;
  `}

  h2 {
    margin: 0;
    color: ${colors.text2};
  }

  .placeholder {
    color: ${colors.text1};

    svg {
      color: ${colors.blue};
    }

    a {
      color: ${colors.text1};
      &:visited {
        color: ${colors.text1};
      }
    }
  }

  svg {
    width: 1.7em;
    height: 1.7em;
    /* stroke-width: 1.3px; */
    color: ${colors.faint_blueish_gray}
  }
`;

const SectionHeader = ({
  className, titleClassName, onClick, title, leftComponent, rightComponent
}) => {
  let titleToUse = title;
  if (isLocalized(title)) {
    titleToUse = <LocalizedSpan stringId={title} />;
  }
  return (
    <Container className={className} onClick={onClick}>
      <div className="placeholder">{leftComponent}</div>
      <h2 className={titleClassName}>{titleToUse}</h2>
      <div className="placeholder">{rightComponent}</div>
    </Container>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element
};

SectionHeader.defaultProps = {
  leftComponent: undefined,
  rightComponent: undefined
};

export default SectionHeader;
