import { getEventString } from './emums';

export function parseEvent(data) {
  const event = {};
  const errors = [];

  const {
    eventId, eventType, timestamp, ...extra
  } = data;

  if (eventId === undefined) {
    errors.push('no-id');
  } else {
    event.id = `${eventId}`;
  }

  if (eventType === undefined) {
    errors.push('no-event-type');
  } else {
    event.type = getEventString(eventType);
  }

  if (timestamp === undefined) {
    errors.push('no-event-date');
  } else {
    event.date = new Date(timestamp * 1000);
  }

  if (errors.length > 0) {
    event.errors = errors;
  }

  if (Object.keys(extra).length > 0) {
    event.extra = extra;
  }

  return event;
}

export function parseEvents(data) {
  return data.map(parseEvent);
}
