import Creatable from 'react-select/creatable';
import React from 'react';
import { Input } from '../../components/TextInput';
import LocalizedSpan, { renderLocalizedString } from '../LocalizedSpan';
import { colors } from '../../styles/variables';

const rfc5322 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmails = value =>
  (value &&
    value
      .split(';')
      .map(address => address.trim())
      .map(address => rfc5322.test(address))
      .every(isValid => isValid));

const valueToObjects = email => (email ?
  email
    .split(';')
    .filter(l => l !== '')
    .map((label, id) => ({ label, id, key: `${label}-${id}` }))
  : []);
const objectsToValue = value => value.map(({ label }) => label).join(';');
const removeValue = ({ email, event, changeSetting }) => {
  const { removedValue } = event;
  const newEmails = email.split(';');
  newEmails.splice(removedValue.id, 1);
  changeSetting('email', newEmails.join(';'));
};

const InvalidValue = ({ inputValue, localeSetting }) => <span>{inputValue}<LocalizedSpan localeSetting={localeSetting} stringId="INVALID_EMAIL" /></span>;
const AddValueHint = ({ localeSetting }) => <LocalizedSpan localeSetting={localeSetting} stringId="ADD_EMAIL" />;


export default ({
  localSettings: { email }, localeSetting, changeSetting
}) => (
  <Creatable
    value={valueToObjects(email)}
    name="email"
    options={[]}
    isClearable={false}
    components={{
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      IndicatorContainer: () => null
     }}
    placeholder={renderLocalizedString(localeSetting, 'Email')}
    onChange={(value, event) => {
      const { action } = event;
      switch (action) {
        case 'select-option':
          break;
        case 'deselect-option':
          break;
        case 'remove-value':
          removeValue({ event, email, changeSetting });
          break;
        case 'pop-value':
          break;
        case 'set-value':
          break;
        case 'clear':
          changeSetting('email', '');
          break;
        case 'create-option':
          changeSetting('email', objectsToValue(value));
          break;
        default:
          console.log('unkown action', action);
      }
    }}
    isValidNewOption={validateEmails}
    noOptionsMessage={({ inputValue }) => (inputValue ?
      <InvalidValue localeSetting={localeSetting} inputValue={inputValue} /> :
      <AddValueHint localeSetting={localeSetting} />)}
    formatCreateLabel={label => `${label}`}
    isMulti
  />
);

/* <Input
  value={email}
  onChange={value => changeSetting('email', value)}
  placeholder="Email"
  localeSetting={localeSetting}
  validate={validateEmails}
  type="email"
/> */
