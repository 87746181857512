import React from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';

import FormText from '../../components/Form/FormText';
import { withApiConsumer } from '../../state/apiProvider';
import { colors } from '../../styles/variables';
import { Leg } from '../Legs';
import LocalizedSpan from '../../components/LocalizedSpan';

const getRoom = (rooms, roomId) => _find(rooms, { id: roomId });

const getReason = (sensor) => {
  if (sensor.apiStatus === 'inactive') {
    return { type: 'out-of-leg', explanation: 'OUTOFLEG_LONG_EXPLANATION' };
  }
  return { type: 'other', explanation: 'OTHER_EXPLANATION' };
};

const InactiveMessageView = ({ rooms, message }) => {
  const room = getRoom(rooms, message.roomId);
  let reason;
  const sensor = _find(_get(room, 'sensors', []), s => (message.affectedSensors || []).includes(s.id));
  if (sensor) {
    reason = getReason(sensor);
  }
  return (
    <div style={{ padding: '1em', flex: 1 }}>
      <FormText><LocalizedSpan stringId="The leg" /> {sensor.id} <LocalizedSpan stringId="in room" /> {room.name} <LocalizedSpan stringId="appears to be out of the leg." /></FormText>
      {reason && <FormText><LocalizedSpan stringId={reason.explanation} /></FormText> }
      <div style={{ padding: '2em 1em' }}>
        <Leg leg={sensor} style={{ border: '1px solid', borderColor: colors.borderColor, borderRadius: '.4em' }} />
      </div>
      {/* <pre>{JSON.stringify(sensor, '', 3)}</pre> */}
    </div>
  );
};

export default withApiConsumer(InactiveMessageView);
