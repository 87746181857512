import React from 'react';
import styled from 'styled-components';
import { Bell } from 'react-feather';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import CollapsibleSection from '../../components/CollapsibleSection';
import { LinkButton } from '../../components/Button';

import { colors } from '../../styles/variables';
import { preserveSearch } from '../../utils';
import FormText from '../../components/Form/FormText';
import LocalizedSpan from '../../components/LocalizedSpan';

const EventListContainer = styled.div`
  overflow-y: scroll;
`;

const EventListStyle = styled.div``;

const EventStyle = styled.div`
  font-size: 1.2em;
  padding: 0.8em;
  margin: 0 0.6em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-top: 1px solid ${colors.itemBorderColor};

  &:first-child {
    border-top: none;
  }

  .action {
    font-size: 0.7em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;

  .event {
    text-transform: capitalize;
  }

  .date {
    margin-bottom: 0.4em;
    font-size: 0.7em;
    font-weight: lighter;
    color: ${colors.text3};
  }
`;

const actionColors = {
  detection: colors.sensor_red
};

const hasLink = type => ['resolution'].includes(type);

const makeLink = (type, id, search) => ({
  search: preserveSearch(
    {
      [`${type}Id`]: id
    },
    search
  )
});

const EventAction = withRouter(({ className, event, location: { search } }) => (
  <LinkButton
    id={`room__event__view--${event.id}`}
    className={className}
    color={actionColors[event.type]}
    to={makeLink(event.type, event.id, search)}
  >
    view
  </LinkButton>
));

export const Event = ({ event }) => (
  <EventStyle id={`room__event--${event.id}`}>
    <TitleContainer>
      <span className="date">
        {moment(event.date).format('dddd, MMMM Do, HH:mm')}
      </span>
      <LocalizedSpan className="event" stringId={event.type} />
    </TitleContainer>{' '}
    {hasLink(event.type) && <EventAction event={event} className="action" />}
  </EventStyle>
);

const History = ({ loading, events }) =>
  (events && events.length > 0 ? (
    <EventList events={events} />
  ) : (
    <FormText
      style={{
        color: colors.text3,
        textAlign: 'center',
        padding: '1em 0'
      }}
    >
      {loading ? <LocalizedSpan stringId="LOADING" /> : <LocalizedSpan stringId="NO_EVENTS_FOUND" />}
    </FormText>
  ));

export const EventList = ({ events }) => (
  <EventListContainer>
    <EventListStyle id="room__history">
      {(events || []).map(event => (
        <Event key={event.id} event={event} />
      ))}
    </EventListStyle>
  </EventListContainer>
);

export default History;
