import React from 'react';
import { compose, withState, lifecycle } from 'recompose';
import styled from 'styled-components';
import { Redirect, withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Logo from '../../assets/logo_black.svg';
import { withAuthConsumer } from '../../state/authProvider';
import { Input } from '../../components/TextInput';
import { Button } from '../../components/Button';
import Hint from '../../components/Hint';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.div`
  img {
    padding-bottom: 2em;
  }

  max-width: 30em;
  min-width: 20em;
  padding-bottom: 20em;
  display: flex;
  flex-direction: column;
`;


const parseCredentials = ({ search }) => {
  try {
    const username = search.match(/username=([a-zA-Z0-9_-]+)/)[1];
    const password = search.match(/password=([a-zA-Z0-9_-]+)/)[1];
    return [username, password];
  } catch (err) {
    return ['', ''];
  }
};

const enhance = compose(
  withState('username', 'setUsername', ''),
  withState('password', 'setPassword', ''),
  withRouter,
  withAuthConsumer,
  lifecycle({
    componentDidMount() {
      const { location, setUsername, setPassword, authenticate } = this.props;
      const [pathUsername, pathPassword] = parseCredentials(location);
      setUsername(pathUsername);
      setPassword(pathPassword);
      if (pathUsername && pathPassword) authenticate(pathUsername, pathPassword);
    }
  })
);

const Login = ({
  className,
  username,
  setUsername,
  password,
  setPassword,
  authenticate,
  authenticated,
  location,
  error,
  loading
}) => {
  const onSubmit = () => authenticate(username, password);
  let referrer = get(location, 'state.referrer', '/');
  if (referrer === '/login' || referrer === '/') {
    referrer = '/feed';
  }
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };
  if (authenticated) window.location.href = referrer || '/feed';
  return (
    <Container className={className}>
      <LoginForm>
        <img style={{ padding: '2rem', width: '16rem' }} src={Logo} alt="Valpas" />
        <Input
          style={{ margin: '.3em 0' }}
          type="text"
          id="login__username"
          value={username}
          onChange={setUsername}
          placeholder="Username"
          onKeyDown={onKeyDown}
        />
        <Input
          style={{ margin: '.3em 0' }}
          type="password"
          id="login__password"
          value={password}
          onChange={setPassword}
          placeholder="Password"
          onKeyDown={onKeyDown}
        />
        <Button
          style={{ margin: '.3em 0' }}
          id="login__submit"
          fill
          onClick={onSubmit}
          loading={loading}
        >
          Login
        </Button>
        {error && (
          <Hint
            type="error"
            text="Wrong username or password"
          />
        )}
      </LoginForm>
    </Container>
  );
};

export default enhance(Login);
