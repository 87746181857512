import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { HardDrive } from 'react-feather';

import CollapsibleSection from '../../components/CollapsibleSection';
import { Sensor, StatusText } from '../SensorStatus';

import { colors } from '../../styles/variables';
import { preserveSearch } from '../../utils';
import LocalizedSpan from '../../components/LocalizedSpan';

const LegListContainer = styled.div`
  overflow-y: scroll;
`;

const LegListStyle = styled.div``;

const LegStyle = styled.div`
  font-size: 1.2em;
  padding: 0.8em;
  margin: 0 0.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.itemBorderColor};

  &:first-child {
    border-top: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  .leg-id {
    margin-right: 2.8em;
    font-size: 0.8em;
    color: ${colors.text2};

    &.title {
      margin-right: 1.8em;
      font-size: 1em;
      color: ${colors.text3};
    }
  }

  .battery {
    font-size: 0.8em;
    color: ${colors.text2};
    &.title {
      font-size: 1em;
      color: ${colors.text3};
    }
  }
  .location-name {
    margin-right: 2em;
    font-size: 0.8em;
    color: ${colors.text2};
    &.title {
      margin-right: 4.3em;
      font-size: 1em;
      color: ${colors.text3};
    }
  }
`;

const HeaderStyle = styled.div`
  position: sticky;
  background: white;
  top: 0;
  display: flex;
  padding: 0.4em 1em;
  justify-content: space-between;
  color: ${colors.text3};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  .title {
    margin-left: 0.8em;
    display: flex;
    flex-direction: column;
  }

  .date {
    margin-bottom: 0.4em;
    font-size: 0.7em;
    font-weight: lighter;
    color: ${colors.text3};
  }
`;

export const Leg = withRouter(({ leg, style, location }) => (
  <LegStyle style={style} id={`leg--${leg.id}`}>
    <StatusContainer>
      <Sensor status={leg.status} id={leg.id} />
      <div className="title">
        {leg.lastMessage && leg.lastMessage > 0 ? (
          <Link
            id={`leg__messageLink--${leg.id}`}
            title="Show more messages"
            to={{ search: preserveSearch({ legId: leg.id }, location.search) }}
            className="date"
          >
            <LocalizedSpan stringId="LAST_MESSAGE" />{' '}
            {moment(leg.lastMessage).fromNow()}
          </Link>
        ) : (
            <span className="date error">
              <LocalizedSpan stringId="NO_LAST_MESSAGE" />
            </span>
          )}
        <StatusText status={leg.status} />
      </div>
    </StatusContainer>
    <Details>
      <span className="location-name">{leg.extra.locationName}</span>
      <span className="leg-id">{leg.id}</span>
      <span className="battery">{leg.battery}{leg.battery !== '—' ? '%' : ''}</span>
    </Details>
  </LegStyle>
));

export const LegList = ({ legs, unpaired }) => (
  <LegListContainer>
    <HeaderStyle>
      <LocalizedSpan stringId="STATUS_TITLE" />
      <Details>
        {unpaired && <LocalizedSpan className="location-name title" stringId="LOCATION_NAME_TITLE" />}
        <LocalizedSpan className="leg-id title" stringId="ID_TITLE" />
        <LocalizedSpan className="battery title" stringId="BATTERY_TITLE_SHORT" />
      </Details>
    </HeaderStyle>
    <LegListStyle>
      {(legs || []).map(leg => (
        <Leg key={leg.id} leg={leg} />
      ))}
    </LegListStyle>
  </LegListContainer>
);

const Legs = ({ legs, unpaired }) => <LegList legs={legs} unpaired={unpaired} />;

export default Legs;
