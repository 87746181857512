import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Modal, { ModalFooter } from '../../components/Modal';
import LocalizedSpan from '../../components/LocalizedSpan';
import BatteryInfo from './BatteryInfo';
import { Button } from '../../components/Button';
import BatteryChangeGuide from '../../assets/install_change_batteries.pdf';

const BatterySummary = ({ location, history }) => {
  const query = queryString.parse(location.search);
  if (query.showBatterySummary) {
    return (
      <Modal
        footer={() => (
          <ModalFooter>
            <Button onClick={() => history.goBack()}>Close</Button>
            <a href={BatteryChangeGuide} target="_blank">
              <Button fill>
                <LocalizedSpan stringId="GUIDE" />
              </Button>
            </a>
          </ModalFooter>
        )}
        title={<LocalizedSpan stringId="BATTERY_TITLE" />}
        onClose={() => history.goBack()}
      >
        <BatteryInfo />
      </Modal>
    );
  }
  return null;
};

export default withRouter(BatterySummary);
