export function parseAction(data) {
  const action = {};
  const errors = [];

  if (data === null) {
    return null;
  }

  const {
    type, sensorId, sensorIds, roomId
  } = data;

  if (type === undefined) {
    errors.push('no-type');
  } else {
    action.type = type;
  }

  if (sensorId === undefined) {
    errors.push('no-sensorId');
  } else {
    action.sensorIds = [`${sensorId}`];
  }

  if (sensorIds !== undefined) {
    action.sensorIds = sensorIds.map(id => `${id}`);
  }

  if (roomId === undefined) {
    errors.push('no-roomId');
  } else {
    action.roomId = `${roomId}`;
  }

  return action;
}

export function parseMessage(data) {
  const message = {};
  const errors = [];

  const {
    id, title, timestamp, body, action, roomId, ...extra
  } = data;

  if (id === undefined) {
    errors.push('no-id');
  } else {
    message.id = `${id}`;
  }

  if (roomId === undefined) {
    errors.push('no-roomId');
  } else {
    message.roomId = `${roomId}`;
  }

  if (title === undefined) {
    errors.push('no-type');
  } else {
    message.type = title;
  }

  if (timestamp === undefined) {
    errors.push('no-date');
  } else {
    message.date = new Date(timestamp * 1000);
  }

  if (body === undefined) {
    errors.push('no-body');
  } else {
    message.text = body;
    const affectedSensors = body.match(/\d{4,}(?!Leg)/g);
    if (affectedSensors) {
      message.affectedSensors = affectedSensors;
    }
  }

  if (action === undefined) {
    errors.push('no-action');
  } else {
    message.action = parseAction(action);
  }

  if (errors.length > 0) {
    message.errors = errors;
  }

  if (Object.keys(extra).length > 0) {
    message.extra = extra;
  }

  return message;
}

export function parse(data) {
  let messagesArray;
  try {
    const { buildingName, messages } = data[0];
    messagesArray = messages.map(m => ({ ...parseMessage(m), hotel: buildingName }));
  } catch (e) {
    throw e;
  }

  return messagesArray;
}

export default {
  parse
};
