import React from 'react';
import { withStateHandlers } from 'recompose';
import { ChevronUp } from 'react-feather';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

import SectionHeader from '../SectionHeader';
import { colors } from '../../styles/variables';

const enhance = withStateHandlers(
  ({ initialCollapsed = false }) => ({
    collapsed: initialCollapsed
  }),
  { toggleCollapsed: ({ collapsed }) => () => ({ collapsed: !collapsed }) }
);

const CollapseIcon = styled(ChevronUp)`
  cursor: pointer;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1);

  ${props => props.collapsed && css`
    transform: rotateX(180deg);
  `}
`;

const Content = styled.div`
  /* transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1); */
  max-height: 100%;
  /* opacity: 1; */

  ${props => props.collapsed && css`
    display: none;
    /* opacity: 0; */
    /* max-height: 0px;
    transform-origin: center; */
  `}
`;

const CollapseContainer = styled.div`
  overflow: scroll;
  border-bottom: 1px solid ${colors.borderColor};

  .sticky-header {
    position: sticky;
    background: white;
    z-index: 100;
    grid-template-rows: 3em;
    top: 0;
  }

  &.top-border {
    border-top: 1px solid ${colors.borderColor};
    border-bottom: none;
  }

  .small-title {
    font-size: 1.2em;
  }
`;

const CollapsibleSection = ({
  icon, title, children, collapsed, toggleCollapsed, className
}) => (
  <CollapseContainer className={className}>
    <SectionHeader className="sticky-header" titleClassName="small-title" title={title} leftComponent={icon} onClick={toggleCollapsed} rightComponent={<CollapseIcon collapsed={collapsed} />} />
    <Content collapsed={collapsed}>
      {children}
    </Content>
  </CollapseContainer>
);

export default enhance(CollapsibleSection);
