import React from 'react';
import { FormText, FormField } from '../../components/Form';
import { TextArea } from '../../components/TextInput';
import LocalizedSpan from '../../components/LocalizedSpan';
import Button, { IconButton } from './index';
import { Phone, Mail } from 'react-feather';
import { FaWhatsapp as Whatsapp } from 'react-icons/fa';

export default () => (
  <FormField label="Contact us">
    <FormText style={{ marginBottom: '.4em' }}>
      <LocalizedSpan stringId="RESOLUTION_CONTACT_US" />
    </FormText>
    <FormText style={{ marginBottom: '.4em' }}>
      <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content max-content', gridGap: '0.5rem' }}>
        <Whatsapp style={{ height: '24px', width: '24px' }} />
        +358 50 338 6662
      </div>
    </FormText>
    <FormText style={{ marginBottom: '.4em' }}>
      <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', gridGap: '0.5rem' }}>
        <Mail /> team@valpas.io
      </div>
    </FormText>
  </FormField>
);

