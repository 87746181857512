import React from "react";
import styled from "styled-components";
import { X } from "react-feather";

import SectionHeader from "../SectionHeader";
import Button, { IconButton } from "../Button";
import { colors } from "../../styles/variables";
import { lifecycle } from "recompose";

const Container = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const ModalStyle = styled.div`
  z-index: 100;
  background: white;
  min-width: 34em;
  max-width: 40em;
  margin: 4em auto;
  border: 1px solid ${colors.borderColor};
  border-radius: 0.4em;

  @media (max-width: 767px) {
    margin: auto;
    min-width: unset;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  animation: slide-fwd-top 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) both;

  @keyframes slide-fwd-top {
    0% {
      transform: translateZ(160px) translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
`;

const Backdrop = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0000005d;
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${colors.borderColor};
  padding: 1em;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = ({ onClose }) => (
  <IconButton color="black" onClick={onClose}>
    <X />
  </IconButton>
);

const enhance = lifecycle({
  componentDidMount() {
    document.body.classList.add("modal-open");
  },
  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }
});

const ModalChild = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-height: 40em;
  }
`;

export const Modal = ({ title = "Modal", onClose, closeText = "close", footer = false, onConfirm, confirmText = "confirm", children }) => (
  <Container>
    <Backdrop onClick={onClose} />
    <ModalStyle>
      <SectionHeader title={title} rightComponent={<CloseButton onClose={onClose} />} />
      <ModalChild>{children}</ModalChild>
      {!footer ? (
        <ModalFooter>
          <Button onClick={onClose}>{closeText}</Button>
          {onConfirm && (
            <Button onClick={onConfirm} fill>
              {confirmText}
            </Button>
          )}
        </ModalFooter>
      ) : (
        footer()
      )}
    </ModalStyle>
  </Container>
);

export default enhance(Modal);
