import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SectionHeader from '../../components/SectionHeader';

const Overview = () => (
  <div>
    <SectionHeader title="Overview" />
      Hotel info
  </div>
);

Overview.propTypes = {
};

export default Overview;
