import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle, Info } from 'react-feather';
import { rgba } from 'polished';
import classNames from 'classnames';

import { colors } from '../../styles/variables';

const HintStyle = styled.div`
  padding: 1em;
  display: flex;
  border-radius: .3em;
  border: 1px solid transparent;
  align-items: center;

  background-color: ${rgba(colors.blueish_gray, 0.2)};
  border-color: ${colors.blueish_gray};

  svg {
    min-width: 1.5em;
    min-height: 1.5em;

  }

  &.default {
    svg {
      color: ${colors.blue2};
    }
  }

  span.hint-text {
    margin-left: 1.5em;
  }

  &.red, &.error {
    background-color: ${rgba(colors.sensor_red, 0.05)};
    border-color: ${colors.sensor_red};
    color: ${colors.text1};
    
    svg {
      color: ${colors.sensor_red};
    }
  }
`;

const getHintIcon = (type) => {
  switch (type) {
    case 'red': {
      return <AlertTriangle />;
    }
    case 'error': {
      return <AlertTriangle />;
    }
    default:
      return <Info />;
  }
};

const Hint = ({
  type, text, className
}) => (
  <HintStyle className={classNames(type, className)}>
    { getHintIcon(type) }
    <span className="hint-text">
      {text}
    </span>
  </HintStyle>
);

Hint.propTypes = {

};

export default Hint;
