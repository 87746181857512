import React from 'react';
import PropTypes from 'prop-types';
import { Bell, Grid, Settings, User, Info } from 'react-feather';
import styled from 'styled-components';

import { colors } from '../../styles/variables';
import NavButton from './NavButton';

const NavBarStyle = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-width: 6.5em;
  min-height: 40em;
  border-right: 1px solid ${colors.borderColor};
  z-index: 50;

  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    min-height: 4em;
    border-right: none;
    border-top: 1px solid ${colors.borderColor};
  }

  .middle {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .profile-tab {
      display: none;
    }

    @media (max-width: 767px) {
      flex: 1;
      padding-top: 0;
      flex-direction: row;

      .profile-tab {
        display: flex;
      }
    }
  }

  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const NavBar = ({ className }) => (
  <NavBarStyle className={className}>
    <div className="middle">
      <NavButton id="navbar__button--feed" to="/feed" preserveSearch exact={false} icon={<Bell />} title="Feed" />
      <NavButton id="navbar__button--rooms" to="/rooms" preserveSearch exact={false} icon={<Grid />} title="Rooms" />
      <NavButton id="navbar__button--guides" to="/guides" exact icon={<Info />} title="Guides" />
      <NavButton id="navbar__button--settings" to="/settings" icon={<Settings />} title="Settings" />
      <NavButton id="navbar__button--profile" className="profile-tab" to="/profile" icon={<User />} title="Profile" />
    </div>
    <div className="bottom">
      <NavButton id="navbar__button--profile" to="/profile" icon={<User />} title="Profile" />
    </div>
  </NavBarStyle>
);

NavBar.propTypes = {
  className: PropTypes.string
};

NavBar.defaultProps = {
  className: ''
};

export default NavBar;
