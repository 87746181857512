import React from 'react';
import { render } from 'react-dom';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import "regenerator-runtime/runtime.js";
import 'normalize.css';
import './styles/main.scss';

import App from './views/App';

const isLocal = (window && window.location && window.location.href && window.location.href.includes('localhost'));

let env = 'staging';
if (isLocal) {
  env = 'development';
} else if (process.env.API_MODE === 'production') {
  env = 'production';
} else if (process.env.API_MODE === 'demo') {
  env = 'demo';
}

const bugsnagClient = bugsnag({
  apiKey: '0c7e87bca1f974fa1fac37f7aa9008da',
  releaseStage: env,
  notifyReleaseStages: ['staging', 'production']
});

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('app')
);
