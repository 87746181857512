import React from 'react';
import { compose, lifecycle } from 'recompose';
import _find from 'lodash/find';
import moment from 'moment';
import styled from 'styled-components';

import { Modal, ModalFooter } from '../../components/Modal';
import { withApiConsumer } from '../../state/apiProvider';
import { FormText, FormField } from '../../components/Form';
import { Button } from '../../components/Button';
import { getResolutionName } from '../../api/parsers/emums';
import InspectionGuide from '../../assets/valpas_inspectionguide.pdf';
import LocalizedSpan from '../../components/LocalizedSpan';

const getRoom = (rooms, roomId) => _find(rooms, { id: roomId });

const renderSuggestion = (type) => {
  switch (type) {
    case 'bed bug':
      return (
        <LocalizedSpan stringId="SUGGESTION_BEDBUG">{' '}
          <a href={InspectionGuide} target="_blank">
            <LocalizedSpan stringId="GUIDES_INSPECTION_GUIDE" />
          </a>
        </LocalizedSpan>
      );
    case 'other bug':
      return (
        <LocalizedSpan stringId="SUGGESTION_OTHER_BUG" />
      );
    default:
      return (
        <LocalizedSpan stringId="SUGGESTION_OTHER" />
      );
  }
};

const Style = styled.div`
  padding: 0.4em 0;
  flex: 1;
`;

const ResolutionEndModal = ({
  rooms,
  resolutions,
  resolutionId,
  closeModal
}) => {
  const resolution = resolutions[resolutionId];
  if (!resolution) {
    return null;
  }
  const room = getRoom(rooms, resolution.roomId);
  const formattedDate = moment(resolution.date).format('dddd, MMMM Do, HH:mm');
  return (
    <Modal
      title="Resolve completed"
      onClose={closeModal}
      footer={() => (
        <ModalFooter>
          <Button onClick={closeModal} fill>
            OK
          </Button>
        </ModalFooter>
      )}
    >
      <Style>
        <FormText style={{ marginBottom: '.4em' }}>
          <LocalizedSpan stringId="RESOLVED_ALL_DETECTIONS" />
          {' '}<strong>{room.name}</strong>{' '}<LocalizedSpan stringId="on" />{' '}
          {formattedDate}.
        </FormText>
        <FormText>
          {renderSuggestion(getResolutionName(resolution.type))}
        </FormText>
        <FormField label="Resolution information:">
          <FormText>
            <LocalizedSpan stringId="Found in trap" />: <LocalizedSpan stringId={getResolutionName(resolution.type)} />
          </FormText>
          {resolution.additionalInformation && (
            <FormText>
              <LocalizedSpan stringId="Additional information">
                {resolution.additionalInformation}
              </LocalizedSpan>
            </FormText>
          )}
        </FormField>
        {/* <FormText><pre>{JSON.stringify(resolution, '', 3)}</pre></FormText> */}
      </Style>
    </Modal>
  );
};

const enhance = compose(
  withApiConsumer,
  lifecycle({
    componentWillReceiveProps(props) {
      const resolutionExists = !!props.resolutions[props.resolutionId];
      if (this.props.resolutionId !== props.resolutionId && !resolutionExists) {
        this.props.getResolution(props.resolutionId);
      }
    },
    componentDidMount() {
      this.props.getResolution(this.props.resolutionId);
    }
  })
);

export default enhance(ResolutionEndModal);
