import React from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { AlertTriangle } from 'react-feather';

import { ExternalLinkButton, LinkButton } from '../../components/Button';
import colors, { getStatusColor } from '../../styles/variables/colors';
import FormText from '../../components/Form/FormText';
import LocalizedSpan from '../../components/LocalizedSpan';

const getMessage = (status) => {
  switch (status) {
    case 'detection':
      return <LocalizedSpan stringId="DESCRIPTION_DETECTION" />;
    case 'online':
      return <LocalizedSpan stringId="DESCRIPTION_ONLINE" />;
    case 'maintenance':
      return <LocalizedSpan stringId="DESCRIPTION_MAINTENANCE" />;
    default:
      return '';
  }
};

const SummaryContainer = styled.div`
  padding: 1em;
  padding-top: 0;
  padding-bottom: 1.5em;
  color: ${colors.text2};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  min-height: 2em;

  .status-action {
    margin-top: 1em;
    display: inline-flex;
    align-items: center;
    text-transform: none;
    background: ${props => getStatusColor(props.status)};

    span {
      flex: 1;
      font-size: 1.2em;
      text-align: center;
    }

    svg {
      margin-right: 1em;
    }
  }

  .info {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .status {
    font-size: 1.4em;
    text-transform: capitalize;
    color: ${props => getStatusColor(props.status)};
  }
`;

const StatusAction = ({ status }) => (
  <ExternalLinkButton className="status-action" to="/feed" round fill color="#fff">
    <AlertTriangle />
    <LocalizedSpan stringId={`FEED_PROMPT_${status.toUpperCase()}`} />
  </ExternalLinkButton>
);

const statusRequiresAction = status => status === 'detection';

const RoomSummary = ({ status }) =>
  (status ? (
    <SummaryContainer status={status}>
      <FormText className="info status">
        <LocalizedSpan stringId={`STATUS_${status.toUpperCase()}`} />
      </FormText>
      <FormText className="info">{getMessage(status)}</FormText>
      {statusRequiresAction(status) && <StatusAction status={status} />}
    </SummaryContainer>
  ) : null);

export default RoomSummary;
