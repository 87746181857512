import React from 'react';
import styled from 'styled-components';

import SectionHeader from '../SectionHeader';
import { colors } from '../../styles/variables';

const CollapseContainer = styled.div`
  overflow: scroll;
  border-bottom: 1px solid ${colors.borderColor};

  .sticky-header {
    position: sticky;
    background: white;
    z-index: 100;
    grid-template-rows: 3em;
    top: 0;
  }

  &.top-border {
    border-top: 1px solid ${colors.borderColor};
    border-bottom: none;
  }

  .small-title {
    font-size: 1.2em;
  }
`;

const Section = ({
  icon, title, children, collapsed, toggleCollapsed, className
}) => (
  <CollapseContainer className={className}>
    <SectionHeader className="sticky-header" titleClassName="small-title" title={title} leftComponent={icon} onClick={toggleCollapsed} />
    <div collapsed={collapsed}>
      {children}
    </div>
  </CollapseContainer>
);

export default Section;
