import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../styles/variables';

const DrawerStyle = styled.div`
  min-width: 30em;
  max-width: 30em;
  border-left: 1px solid ${colors.borderColor};
  background: white;
  overflow-y: scroll;
  /* animation: mymove .3s ease-in-out; */

  @keyframes mymove {
    from {
      transform: translateX(30em);
    }
    to {
      transform: translateX(0);
    }
  }
  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
    min-width: unset;
    border: none;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
  }
`;

const Drawer = ({ className, children }) => (
  <DrawerStyle className={className}>{children}</DrawerStyle>
);

Drawer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Drawer.defaultProps = {
  className: ''
};

export default Drawer;
