import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _sortBy from 'lodash/sortBy';
import { Link, withRouter } from 'react-router-dom';

import { colors } from '../../styles/variables';
import green from '../../assets/sensor_green.svg';
import orange from '../../assets/sensor_orange.svg';
import red from '../../assets/sensor_red.svg';
import noStatus from '../../assets/sensor_no_status.svg';
import basic from '../../assets/sensor_black.svg';
import error from '../../assets/sensor_error.svg';

import { preserveSearch } from '../../utils';
import LocalizedSpan, { isLocalized } from '../../components/LocalizedSpan';

const icons = {
  online: green,
  offline: orange,
  calibration: orange,
  detection: red,
  debug: orange,
  inactive: orange,
  'mqtt will': orange,
  'out of leg': error,
  'no status': noStatus
};

export const SensorContainer = styled.div`
  img {
    width: 1em;
  }
`;

export const Sensor = ({ id, status }) => (
  <SensorContainer>
    <img
      src={icons[status]}
      alt={status}
      title={`Sensor ${id} status ${status}`}
    />
  </SensorContainer>
);

const StyledStatus = styled.span`
  ::first-letter {
    text-transform: capitalize;
  }
`;

export const StatusText = ({ status }) => {
  let statusToShow = status;
  if (isLocalized(status)) {
    statusToShow = <LocalizedSpan stringId={status} />;
  }
  return (
    <StyledStatus>
      {statusToShow}
    </StyledStatus>
  );
};

const SensorLinkContainer = styled(Link)`
  color: ${colors.text2};
  display: inline-flex;
  align-items: center;
  margin-right: 0.4em;
  padding: 0.2em;
  border-radius: 0.2em;
  /* border: 1px solid ${colors.text2}; */
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  transition: all .1s ease-in;

  &:hover {
    transform: translateY(-1px);
  } */

  img {
    margin-right: 0.4em;
  }
`;

export const SensorLink = withRouter(({ sensorId, className, location }) => (
  <SensorLinkContainer className={className} to={{ search: preserveSearch({ legId: sensorId }, location.search) }} title="Show leg information">
    <img src={basic} height="14" alt="sensor" />
    <span>{sensorId}</span>
  </SensorLinkContainer>
));

const SensorType = {
  id: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired
};

Sensor.propTypes = SensorType;

const SensorStatusStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  color: white;
  white-space: nowrap;
`;

const filteredSensors = sensors => _sortBy(sensors.filter(s => !!s.status), 'status').slice(0, 8);

const SensorStatus = ({ className, sensors }) => {
  const visibleSensors = filteredSensors(sensors);
  const hasSensors = visibleSensors.length > 0;
  return (
    <SensorStatusStyle className={className}>
      {hasSensors
        ? visibleSensors.map(s => (
          <Sensor key={s.id} id={s.id} status={s.status} />
          ))
        : <LocalizedSpan stringId="NO_LEGS" />}
    </SensorStatusStyle>
  );
};

SensorStatus.propTypes = {
  sensors: PropTypes.arrayOf(SensorType).isRequired
};

export default SensorStatus;
