import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Modal from '../../components/Modal';
import LegInfo from './LegInfo';
import LocalizedSpan from '../../components/LocalizedSpan';

const LegModal = ({ location, history }) => {
  const query = queryString.parse(location.search);
  if (query.legId) {
    return (
      <Modal title={<LocalizedSpan stringId="LEG_INFORMATION_TITLE" />} onClose={() => history.goBack()}>
        <LegInfo legId={query.legId} />
      </Modal>
    );
  }
  return null;
};

export default withRouter(LegModal);
