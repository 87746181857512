import { getResolutionString } from './emums';

export function parse(data) {
  const resolution = {};
  const errors = [];

  const {
    resolutionId, roomId, timestamp, type, additionalInformation, ...extra
  } = data;

  if (resolutionId === undefined) {
    errors.push('no-id');
  } else {
    resolution.id = `${resolutionId}`;
  }

  if (roomId === undefined) {
    errors.push('no-roomId');
  } else {
    resolution.roomId = `${roomId}`;
  }

  if (timestamp === undefined) {
    errors.push('no-resolution-date');
  } else {
    resolution.date = new Date(timestamp * 1000);
  }

  if (type === undefined) {
    errors.push('no-resolution-type');
  } else {
    resolution.type = getResolutionString(type);
  }

  if (additionalInformation !== undefined) {
    resolution.additionalInformation = additionalInformation;
  }

  if (errors.length > 0) {
    resolution.errors = errors;
  }

  if (Object.keys(extra).length > 0) {
    resolution.extra = extra;
  }

  return resolution;
}

export default {
  parse
};
