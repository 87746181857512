import { parse as parseSensor } from './sensor';
import { getRoomStatusFromSensorStatuses } from './emums';

export function parse(data) {
  const room = {};
  const errors = [];

  const {
    roomId, roomName, roomSensors, ...extra
  } = data;

  if (roomId === undefined) {
    errors.push('no-id');
  } else {
    room.id = `${roomId}`;
  }

  if (roomName === undefined) {
    errors.push('no-name');
  } else {
    room.name = `${roomName}`;
  }

  if (roomSensors !== undefined) {
    room.sensors = roomSensors.map(parseSensor);
    room.status = getRoomStatusFromSensorStatuses(room.sensors.map(s => s.status));
  }

  if (errors.length > 0) {
    room.errors = errors;
  }

  room.extra = extra;
  return room;
}

export default {
  parse
};
