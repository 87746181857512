import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { colors } from '../../styles/variables';
import LocalizedSpan, { isLocalized } from '../LocalizedSpan';
import LoadingIndicator from '../LoadingIndicator';

const ButtonStyle = css`
  position: relative;
  border: none;
  color: ${colors.blue2};
  margin: .3em;
  padding: .8em;
  border-radius: .2em;
  font-size: .9em;
  text-transform: uppercase;
  overflow: hidden;
  
  cursor: pointer;
  outline: none;

  ${props =>
    props.fill &&
    css`
      background: ${colors.blue2};
      color: white;

      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
      transition: all 0.1s ease-in;

      &:hover {
        transform: translateY(-1px);
      }
    `}

  ${props =>
    props.disabled &&
    css`
      background: ${colors.faintest_gray};
      &:hover {
        transform: none;
      }
    `}

  ${props =>
    props.round &&
    css`
      border-radius: 10em;
    `}

  &:active {
    .ripple {
      background: ${colors.icon_highlight};
      opacity: 0.15;
      border-radius: .2em;
      /* animation-play-state: running; */
      transform: scale(1.1);
    }
  }

  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  &:disabled {
    color: ${colors.faint_blueish_gray};
    cursor: not-allowed;
  }

  .ripple {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 10em;
    transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1);
    /* animation: ripple .3s; */
    transform: scale(0);
  }
`;

const StyledButton = styled.button`
  background: transparent;
  ${ButtonStyle};

  &:hover {
  }
`;

const ButtonText = ({ children }) => {
  if (isLocalized(children)) {
    // is localized
    return <LocalizedSpan stringId={children} />;
  }
  return children; // just render text, or element which was given
};

export const Button = ({
  onClick,
  children,
  disabled,
  fill,
  round,
  loading,
  ...props
}) =>
  (!loading ? (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      fill={fill}
      round={round}
      {...props}
    >
      <div className="ripple" />
      <ButtonText>{children}</ButtonText>
    </StyledButton>
  ) : (
    <div style={{ margin: 'auto 1rem' }}>
      <LoadingIndicator color="black" size="16px" />
    </div>
  ));

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fill: PropTypes.bool
};

Button.defaultProps = {
  disabled: false,
  fill: false
};

const StyledLink = styled(Link)`
  ${ButtonStyle};

  ${({ alert }) => alert && css`background: ${colors.sensor_orange}`}

  color: ${props => props.color};
  span {
    color: ${props => props.color} !important;
  }

  text-decoration: none;

  &:visited {
    color: ${props => props.color};
  }
`;

const ExternalStyledLink = styled.a`
  ${ButtonStyle};

  ${({ alert }) => alert && css`background: ${colors.sensor_orange}`}

  color: ${props => props.color};
  span {
    color: ${props => props.color} !important;
  }

  text-decoration: none;

  &:visited {
    color: ${props => props.color};
  }
`;

export const ToggleableButton = ({ toggled, ...buttonProps }) => (
  <Button {...buttonProps} fill={toggled} />
);

export const ExternalLinkButton = ({ to, children, ...props }) => (
  <ExternalStyledLink href={to} {...props}>
    <ButtonText>{children}</ButtonText>
  </ExternalStyledLink>
);

export const LinkButton = ({ to, children, ...props }) => (
  <StyledLink to={to} {...props}>
    <ButtonText>{children}</ButtonText>
  </StyledLink>
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string
};

LinkButton.defaultProps = {
  color: colors.blue2
};

const IconButtonStyle = styled.button`
  ${ButtonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: auto;
  background: transparent;

  &:active {
    .ripple {
      border-radius: 10em;
      transform: scale(1);
    }
    svg {
      color: ${colors.icon_active};
    }
  }

  svg {
    /* stroke-width: 1.3px; */
    color: ${props => props.color};
  }

  @keyframes ripple {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 0.15;
    }
  }
`;

export const IconButton = ({
  onClick, children, color, disabled
}) => (
  <IconButtonStyle color={color} onClick={onClick} disabled={disabled}>
    <div className="ripple" />
    {children}
  </IconButtonStyle>
);

export default Button;
