const apiUrls = {
  production: 'https://app.totemprotected.com/api/v1',
  staging: 'https://staging.totemprotected.com/api/v1',
  demo: 'https://staging.valpas.io/api/v1'
};

const API_BASE = apiUrls[process.env.API_MODE || 'staging'];

function getJSON(path, apiToken) {
  const apiUrl = `${API_BASE + path}`;
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`
    }
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(`API ERROR: ${apiUrl}`);
      }
      return res.json();
    })
    .then(res =>
      res)
    .catch(err => Promise.reject(err));
}

function requestJSON(path, method, json, apiToken) {
  const apiUrl = `${API_BASE + path}`;
  if (!['PUT', 'POST'].includes(method)) {
    throw new Error(`Unsupported HTTP request: ${method}`);
  }
  return fetch(apiUrl, {
    method,
    body: JSON.stringify(json),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`
    }
  })
    .then((res) => {
      if (res.status === 204) {
        return true;
      }
      if (res.status !== 200) {
        throw new Error(`API ERROR: ${apiUrl}`);
      }
      return res.json();
    })
    .then(res =>
      res)
    .catch(err => Promise.reject(err));
}

export default {
  getJSON,
  requestJSON
};
