import React from 'react';
import styled from 'styled-components';
import { compose, withState } from 'recompose';
import _find from 'lodash/find';
import { withRouter } from 'react-router-dom';

import Modal, { ModalFooter } from '../../components/Modal';
import Button from '../../components/Button';
import { FormText, FormField } from '../../components/Form';
import RadioButtons from '../../components/RadioButtons';
import { TextArea } from '../../components/TextInput';
import { withApiConsumer } from '../../state/apiProvider';
import InspectionGuide from '../../assets/valpas_inspectionguide.pdf';
import { preserveSearch } from '../../utils';
import LocalizedSpan from '../../components/LocalizedSpan';
import SupportContact from '../../components/SupportContact'

const resolutionTypes = ['bed bug', 'other bug', 'other'];

const Style = styled.div`
  padding: 0.4em 0;
  flex: 1;
`;

const enhance = compose(
  withState('resolutionType', 'setResolutionType', ''),
  withState('additionalInformation', 'setAdditionalInformation', ''),
  withState('isSubmitting', 'setSubmitting', false),
  withApiConsumer,
  withRouter,
);

const validate = (resolution) => {
  if (!resolution.resolutionType) {
    return false;
  }
  return true;
};

const getRoom = (rooms, roomId) => _find(rooms, { id: roomId });

const ResolveForm = ({
  className,
  history,
  style,
  rooms,
  resolutionType,
  setResolutionType,
  additionalInformation,
  setAdditionalInformation,
  message,
  saveResolution,
  localeSetting,
  isSubmitting,
  setSubmitting
}) => {
  const room = getRoom(rooms, message.roomId);
  const resolution = {
    resolutionType,
    additionalInformation,
    roomId: Number(message.roomId)
  };
  const isValid = validate(resolution);
  const submitResolve = () => {
    setSubmitting(true);
    saveResolution(resolution).then((res) => {
      console.log(res);
      if (res && res.resolutionId) {
        history.replace(`?${preserveSearch(
          { resolutionId: res.resolutionId },
          history.search,
        )}`);
      }
    });
  };
  const closeModal = () => history.goBack();
  if (!room) {
    return null;
  }
  return (
    <Modal
      title="Resolve detection"
      onClose={closeModal}
      footer={() => (
        <ModalFooter>
          <Button id="resolve__cancel" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            id="resolve__submit"
            onClick={submitResolve}
            fill
            loading={isSubmitting}
            disabled={!isValid}
          >
            <LocalizedSpan stringId="RESOLVE" />
          </Button>
        </ModalFooter>
      )}
    >
      <Style className={className} style={style}>
        <FormText>
          <LocalizedSpan stringId="DETECTION_STEP_1">
            <strong>{room.name}</strong>.
          </LocalizedSpan>
        </FormText>
        <FormText>
          <LocalizedSpan stringId="DETECTION_STEP_2" />
        </FormText>
        <FormText>
          <LocalizedSpan stringId="DETECTION_STEP_3">
            <strong>{room.name}</strong>.
          </LocalizedSpan>
        </FormText>
        <FormField label="What was in the trap?" required>
          <RadioButtons
            id="resolve__options"
            options={resolutionTypes}
            selected={resolutionType}
            onChange={setResolutionType}
          />
        </FormField>
        <FormField label="Additional information">
          <TextArea
            id="resolve__additionalInfo"
            value={additionalInformation}
            onChange={setAdditionalInformation}
            localeSetting={localeSetting}
          />
        </FormField>
        <SupportContact />
      </Style>
    </Modal>
  );
};

export default enhance(ResolveForm);
