import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { XCircle } from 'react-feather';

import { colors } from '../../styles/variables';
import LocalizedSpan, { isLocalized, renderLocalizedString } from '../LocalizedSpan';

const InputContainer = styled.span`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .clear-button {
    position: absolute;
    cursor: pointer;
    top: .9em;
    right: .6em;
    height: 1.2em;
    color: ${colors.text3};
  }

  .invalid-hint {
    color: red;
    text-align: center;
  }
`;

const Style = css`
  flex: 1;
  padding: 0.6em;
  border-radius: 0.2em;
  font-size: 1em;
  border: 1px solid ${colors.borderColor};
  color: ${colors.text2};
  background: ${colors.inputBackground};

  ${props => !props.valid && css`
    border-color: red;
  `}

  &:focus {
    background: white;
  }

  ::placeholder {
    color: ${colors.text3};
  }
`;

const StyledInput = styled.input`
  ${Style};
`;

const handleEvent = cb => e => cb(e.target.value);
const clearInput = cb => () => cb('');
const getPlaceholder = (placeholder, locale) => (isLocalized(placeholder)
  ? renderLocalizedString(locale, placeholder)
  : placeholder);

export const Input = ({
  type, value, onChange, placeholder, clearable, validate, invalidHint, localeSetting, ...props
}) => {
  const placeholderToUse = getPlaceholder(placeholder, localeSetting);
  const isValid = validate(value);
  return (
    <InputContainer>
      <StyledInput
        type={type}
        value={value}
        valid={isValid}
        onChange={handleEvent(onChange)}
        placeholder={placeholderToUse}
        {...props}
      />
      {clearable && (value && (
        <XCircle className="clear-button" onClick={clearInput(onChange)} />
      ))}
      {!isValid && <LocalizedSpan className="invalid-hint" stringId={invalidHint} />}
    </InputContainer>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  validate: PropTypes.func,
  invalidHint: PropTypes.string,
  localeSetting: PropTypes.string.isRequired
};

Input.defaultProps = {
  placeholder: 'Write here',
  clearable: false,
  validate: () => true,
  invalidHint: undefined
};

const StyledTextArea = styled.textarea`
  ${Style};
  resize: vertical;
`;

export const TextArea = ({
  value, onChange, placeholder, validate, localeSetting, ...props
}) => {
  const placeholderToUse = getPlaceholder(placeholder, localeSetting);
  return (
    <StyledTextArea
      value={value}
      onChange={handleEvent(onChange)}
      valid={validate(value)}
      placeholder={placeholderToUse}
      {...props}
    />
  );
};

TextArea.defaultProps = {
  placeholder: 'Write here',
  validate: () => true
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  localeSetting: PropTypes.string.isRequired
};

export default Input;
