import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../styles/variables';
import LocalizedSpan from '../LocalizedSpan';

const HeaderStyle = styled.div`
  /* height: 6em; */
  /* padding: 0 2em; */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  h2 {
    flex: 1;
    padding: 2.4rem;
    color: ${colors.text2};
    padding-bottom: 1em;
    margin: 0;
  }

  .children {
    flex: 1;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${colors.borderColor};
  }

  @media (max-width: 767px) {
    h2 {
      padding: 1em;
      padding-bottom: 0.2em;
    }
    .children {
      padding-bottom: 0;
    }
  }
`;

const PageHeader = ({ titleStringId, className, children }) => (
  <HeaderStyle
    // className={className}
    id={`page__header--${titleStringId.toLowerCase()}`}
  >
    <h2><LocalizedSpan stringId={titleStringId} /></h2>
    {children && <div className="children">{children}</div>}
  </HeaderStyle>
);

PageHeader.propTypes = {
  titleStringId: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.element
};

PageHeader.defaultProps = {
  className: '',
  children: undefined
};

export default PageHeader;
