const palette = {
  blueish_white: '#F6F6F7',
  faintest_gray: '#EDEDF1',
  super_faint_grey: '#A7B8CA66',
  faint_grey: '#A7B8CA',
  faint_blueish_gray: '#9BADC0',
  mega_faint_blueish_gray: '#E6E6FF',
  dark_blueish_gray: '#445261',
  blueish_black: '#343e4b',
  blueish_gray: '#96BAE5',
  blue: '#4D8BD3',
  blue2: '#4A90E2',
  pink: '#f27192',
  green: '#46EE6B',
  orange: '#FF8300'
};

const colors = {
  ...palette,
  icon_faint: palette.super_faint_grey,
  icon_inactive: palette.faint_blueish_gray,
  icon_active: palette.blue,
  icon_highlight: palette.blue,
  icon_red: palette.pink,
  sensor_green: palette.green,
  sensor_red: palette.pink,
  sensor_orange: palette.orange,
  text1: palette.blueish_black,
  text2: palette.dark_blueish_gray,
  text3: palette.faint_blueish_gray,
  borderColor: palette.super_faint_grey,
  itemBorderColor: palette.mega_faint_blueish_gray,
  focusedBorderColor: palette.faint_grey,
  containerBackground: palette.blueish_white,
  inputBackground: palette.faintest_gray
};

export default colors;

export function getStatusColor(status) {
  switch (status) {
    case 'detection': return colors.sensor_red;
    case 'maintenance': return colors.sensor_orange;
    case 'online': return colors.sensor_green;
    default: return colors.faintest_gray;
  }
}
