import { parse as parseRoom } from './room';

export function parseRooms(data) {
  const roomsMap = {};
  try {
    const { buildingName, rooms } = data[0];
    const arr = rooms.map(r => ({ ...parseRoom(r), hotel: buildingName }));
    arr.forEach((r) => { roomsMap[r.id] = r; });
  } catch (e) {
    throw e;
  }

  return roomsMap;
}

export default {
  parseRooms
};
