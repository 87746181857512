import _flatMap from 'lodash/flatMap';
import _map from 'lodash/map';
import { getSensorStatusString } from './emums';

export function parse(data) {
  const sensor = {};
  const errors = [];

  const {
    sensorId,
    status,
    battery,
    lastMessage,
    ...extra
  } = data;

  if (sensorId === undefined) {
    errors.push('no-id');
  } else {
    sensor.id = `${sensorId}`;
  }

  if (status === undefined) {
    errors.push('no-status');
    sensor.status = 'no status';
  } else {
    sensor.status = getSensorStatusString(status);
    sensor.apiStatus = sensor.status;
    if (sensor.status === 'calibration') {
      sensor.status = 'online';
    }
    if (sensor.status === 'inactive') {
      sensor.status = 'out of leg';
    }
  }

  if (battery === undefined) {
    errors.push('no-battery');
  } else if (battery > 100) {
    sensor.battery = '—';
  } else {
    sensor.battery = battery;
  }

  if (lastMessage === undefined) {
    errors.push('no-lastMessage');
  } else {
    sensor.lastMessage = new Date(lastMessage * 1000);
    const diff = (Date.now() - sensor.lastMessage);
    if (diff >= 172800000) {
      sensor.status = 'offline';
    }
  }

  if (errors.length > 0) {
    sensor.errors = errors;
  }

  sensor.extra = extra;
  return sensor;
}

export function parseMessage(data, sensorId) {
  const message = {};
  const errors = [];

  const {
    timestamp,
    messageId,
    detectionId,
    dataId,
    action,
    hasMetadata,
    sensorValues,
    ...extra
  } = data;

  if (sensorId === undefined) {
    errors.push('no-sensorId');
  } else {
    message.sensorId = `${sensorId}`;
  }

  if (timestamp === undefined) {
    errors.push('no-date');
  } else {
    message.date = new Date(timestamp * 1000);
  }

  if (messageId === undefined) {
    errors.push('no-id');
  } else {
    message.id = `${messageId}`;
  }

  if (detectionId === undefined) {
    errors.push('no-detectionId');
  } else {
    message.detectionId = `${detectionId}`;
  }

  if (dataId === undefined) {
    errors.push('no-dataId');
  } else {
    message.dataId = `${dataId}`;
  }

  if (action === undefined) {
    errors.push('no-action');
  } else {
    message.type = getSensorStatusString(action);
    if (message.type === 'inactive') {
      message.apiType = 'inactive';
      message.type = 'out of leg';
    }
  }

  message.hasMetadata = hasMetadata;
  message.sensorValues = sensorValues;

  if (errors.length > 0) {
    message.errors = errors;
  }

  message.extra = extra;
  return message;
}

export function parseQueryResult(result) {
  return _flatMap(result.data, (messages, id) => _map(messages, data => parseMessage(data, id)));
}

export default {
  parse,
  parseQueryResult
};
