import React from 'react';
import styled from 'styled-components';
import Download from 'react-feather/dist/icons/file';

import { colors } from '../../styles/variables';
import PageHeader from '../../components/PageHeader';
import { FormText } from '../../components/Form';

import InspectionGuide from '../../assets/valpas_inspectionguide.pdf';
import AttachingValpasLegs from '../../assets/guides/attaching_valpas_legs.pdf';
import DashboardInstructions from '../../assets/guides/dashboard_instructions.pdf';
import EmailNotifications from '../../assets/guides/email_notifications.pdf';
import GeneralDescription from '../../assets/guides/general_description.pdf';
import GetStarted from '../../assets/guides/get_started.pdf';
import GuestSuspicion from '../../assets/guides/guest_suspicion.pdf';
import InstallingChangingBatteries from '../../assets/guides/installing_changing_batteries.pdf';
import InstallingGateway from '../../assets/guides/installing_gateway.pdf';
import PairingModules from '../../assets/guides/pairing_modules.pdf';
import PlacingSmartLegs from '../../assets/guides/placing_smart_legs.pdf';
import ResolvingDetection from '../../assets/guides/resolving_detection.pdf';
import ValpasSystem from '../../assets/guides/valpas_system.pdf';

import LocalizedSpan from '../../components/LocalizedSpan';

const GuideItem = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0.4em;
  padding: 0.4em;
  border-radius: 0.3em;
  border: 1px solid ${colors.borderColor};
  width: 8em;
  height: 8em;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  a {
    color: black;

    &:visited {
      color: initial;
    }

    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      color: ${colors.text2};
      flex: 1;
    }

    svg {
      color: ${colors.icon_active};
      flex: 1;
    }
  }
`;

const Guide = ({ titleStringId, src }) => (
  <GuideItem>
    <a href={src} target="_blank">
      <Download />
      <LocalizedSpan stringId={titleStringId} />
    </a>
  </GuideItem>
);

const GuidesContainer = styled.div`
  padding: 1.2em 2.4em;
  display: flex;
  flex-wrap: wrap;

  h1 {
    font-weight: normal;
  }
`;

const Subtitle = ({ stringId }) => (
  <h2 style={{ margin: 0, marginLeft: '2.4rem', color: colors.text2 }}>
    <LocalizedSpan stringId={stringId} />
  </h2>);

const Guides = () => (
  <div style={{ flex: 1, overflowY: 'scroll' }}>
    <PageHeader titleStringId="GUIDES_TITLE" />
    <FormText style={{ paddingLeft: '2.4em', paddingTop: '1em' }}>
      <LocalizedSpan stringId="GUIDES_DESCRIPTION" />
    </FormText>
    <GuidesContainer>
      <Guide titleStringId="GUIDES_GETTING_STARTED" src={GetStarted} />
    </GuidesContainer>
    <Subtitle stringId="GENERAL" />
    <GuidesContainer>
      <Guide
        titleStringId="GUIDES_GENERAL_DESCRIPTION"
        src={GeneralDescription}
      />
      <Guide titleStringId="GUIDES_VALPAS_SYSTEM" src={ValpasSystem} />
    </GuidesContainer>
    <Subtitle stringId="SOLVING_DETECTIONS_AND_USING_THE_DASHBOARD" />
    <GuidesContainer>
      <Guide
        titleStringId="GUIDES_RESOLVING_DETECTION"
        src={ResolvingDetection}
      />
      <Guide titleStringId="GUIDES_INSPECTION_GUIDE" src={InspectionGuide} />
      <Guide
        titleStringId="GUIDES_DASHBOARD_INSTRUCTIONS"
        src={DashboardInstructions}
      />
      <Guide
        titleStringId="GUIDES_EMAIL_NOTIFICATIONS"
        src={EmailNotifications}
      />
      <Guide titleStringId="GUIDES_SUSPICION_BY_GUEST" src={GuestSuspicion} />
    </GuidesContainer>
    <Subtitle stringId="INSTALLATION_AND_MAINTENANCE" />
    <GuidesContainer>
      <Guide
        titleStringId="GUIDES_ATTACHING_VALPAS_LEGS"
        src={AttachingValpasLegs}
      />
      <Guide
        titleStringId="GUIDES_INSTALLING_GATEWAY"
        src={InstallingGateway}
      />
      <Guide titleStringId="GUIDES_PAIRING_MODULES" src={PairingModules} />
      <Guide titleStringId="GUIDES_PLACING_SMART_LEGS" src={PlacingSmartLegs} />
      <Guide
        titleStringId="GUIDES_INSTALLING_CHANGING_BATTERIES"
        src={InstallingChangingBatteries}
      />
    </GuidesContainer>
  </div>
);

export default Guides;
