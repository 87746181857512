import React from 'react';

import { withApiConsumer } from '../../state/apiProvider';
import localizations from '../../assets/localizations.json';
import { log } from '../../utils/';

export const renderLocalizedString = (locale, stringId) => {
  try {
    return localizations[stringId][locale];
  } catch (e) {
    log.warn(`Localization for ${locale} not found for stringId ${stringId}`);
    return stringId;
  }
};

export const isLocalized = stringId => !!localizations[stringId];

const LocalizedSpan = ({
  localeSetting, className, style, stringId, children
}) => {
  const content = renderLocalizedString(localeSetting, stringId);
  if (Array.isArray(content)) {
    return content.map(line => <span className={className} style={{ display: "block" }}>{line}</span>);
  }
  return (
    <span className={className} style={style}>
      {content}
      {children}
    </span>
  );
}

export default withApiConsumer(LocalizedSpan);
