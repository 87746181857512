import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import queryString from 'query-string';
import moment from 'moment';

import { colors } from '../../styles/variables';
import { preserveSearch } from '../../utils/';

import Room from '../Room';
import { LinkButton } from '../../components/Button';
import { SensorLink } from '../SensorStatus';
import LocalizedSpan from '../../components/LocalizedSpan';

const ItemStyle = styled.div`
  min-width: 40em;
  background: #f2f5f9;
  display: flex;
  align-items: center;
  border: 1px solid #a7b8ca66;
  border-radius: 0.4em;
  padding: 0.6em;

  @media (max-width: 767px) {
    min-width: unset;
    /*
    flex-direction: column;
    font-size: .8em;
    align-items: stretch; */
  }

  .room-left {
    @media (max-width: 767px) {
      font-size: 0.8em;
    }
    /* margin: 0; */
    /* border: none; */
    /* @media (max-width: 767px) {
      align-self: flex-start;
    } */
  }

  .events-right {
    flex: 1;
    /* @media (max-width: 767px) {
      margin-left: 0;
      margin-top: .6em;
    } */
  }
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-left: 0.6em;
`;

const Event = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2em;
  border: 1px solid ${colors.borderColor};
  border-radius: .4em;
  padding: .5em;
  margin-bottom: .4em;
  background: white;
  /* max-height: 1.65em; */

  &:last-child {
    margin-bottom: 0;
  }

  div.title {
    display: flex;
    flex-direction: column;
    font-size: .9em;
    margin-bottom: .8em;
    padding-left: .3em;

    .date {
      margin-bottom: .4em;
      font-size: .8em;
      font-weight: lighter;
      color: ${colors.text3};
    }
  }

  &.detection {
    /* border-left-color: ${colors.sensor_red}; */
  }

  a {
    font-size: .8em;
  }

  span {
    color: ${colors.text1};

    &.detection {
      margin: 0;
      /* color: ${colors.sensor_red}; */
    }
  }

  .message-action {
    font-size: .7em;
  }

  .sensors-affected {
    padding-top: .6em;
  }
`;

const getMessageAction = message =>
  (message.type === 'detection' ? 'resolve' : 'view');

const formatEventText = (e, affectedSensors, roomName) => {
  const isPlural = affectedSensors && affectedSensors.length > 1;
  return (
    <span>
      <LocalizedSpan style={{ textTransform: 'capitalize' }} stringId={isPlural ? 'legs' : 'leg'} />{' '}
      <strong>{affectedSensors && affectedSensors.join(', ')}{' '}</strong>
      <LocalizedSpan stringId={`feed_${e.type}`} />{' '}
      <LocalizedSpan stringId="in room" />
      <strong>{` ${roomName}`}</strong>
    </span>
  );
};

const getSearchString = (messageId, location) =>
  preserveSearch({ messageId }, location.search);

const Events = withRouter(({
  location, className, messages, roomName
}) => {
  const getAffectedSensors = (e) => {
    if (e.action && e.action.sensorIds) {
      return e.action.sensorIds;
    }
    if (e.affectedSensors) {
      return e.affectedSensors;
    }
    return [];
  };
  return (
    <EventList className={className}>
      {messages.map(e => (
        <Event key={e.id} className={e.type}>
          <div className="title">
            <span className="date">
              {moment(e.date).format('dddd, MMMM Do, HH:mm') || 'no date'}
            </span>
            <span className={e.type}>{formatEventText(e, getAffectedSensors(e), roomName)}</span>
            <span className="sensors-affected">
              {getAffectedSensors(e).map(s => (
                <SensorLink key={s} sensorId={s} />
              ))}
            </span>
          </div>
          <LinkButton
            fill
            color="white"
            className="message-action"
            backgroundColor={e.type === 'detection' && colors.sensor_red}
            to={{ search: getSearchString(e.id, location) }}
          >
            {getMessageAction(e)}
          </LinkButton>
        </Event>
    ))}
    </EventList>
  );
});

const FeedItem = ({
  className, room, messages, customRoomPath
}) =>
  (room !== undefined && messages.length > 0 ? (
    <ItemStyle className={className} id={`feed__item--${room.id}`}>
      <Room className="room-left" room={room} customLinkPath={customRoomPath} />
      <Events className="events-right" roomName={room.name} messages={messages} />
    </ItemStyle>
  ) : null);

export default FeedItem;
