import React, { createContext } from 'react';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _get from 'lodash/get';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import moment from 'moment';

import { log } from '../../utils/';

import {
  getRooms,
  getRoomEvents,
  getFeed,
  getSensorMessages,
  loadResolve,
  postResolve,
  getSettings,
  putSettings,
  updatePassword
} from '../../api';
import { withAuthConsumer } from '../authProvider';

const ApiContext = createContext();

const TWO_WEEKS = 1209600000;

export const withApiProvider = Component => props => (
  <ApiProvider>
    <Component {...props} />
  </ApiProvider>
);

export const withApiConsumer = Component => props => (
  <ApiContext.Consumer>
    {value => <Component {...value} {...props} />}
  </ApiContext.Consumer>
);

const ApiProvider = withAuthConsumer(class extends React.Component {
  state = {
    error: undefined,
    showDebug: true,
    feed: [],
    rooms: {},
    resolutions: {},
    sensorMessages: { loading: false },
    settings: {}
  };

  componentDidMount() {
    this.loadSettings();
  }

    getResolution = (resolutionId) => {
      loadResolve(resolutionId, this.props.apiToken).then(resolution =>
        this.setState({
          resolutions: {
            ...this.state.resolutions,
            [resolution.id]: resolution
          }
        }));
    };

    setLanguage = (locale) => {
      console.log('SETTINGS_SET_LANGUAGE', locale);
      moment.locale(locale);
      console.log(moment.locale());
    };

    loadRoomEvents = (roomId) => {
      this.setState({
        rooms: {
          ...this.state.rooms,
          [roomId]: { ...this.state.rooms[roomId], eventsLoading: true }
        }
      });
      getRoomEvents(roomId, this.props.apiToken).then((events) => {
        if (roomId) {
          this.setState({
            rooms: {
              ...this.state.rooms,
              [roomId]: {
                ...this.state.rooms[roomId],
                eventsLoading: false,
                events
              }
            }
          });
        }
      });
    };

    saveResolution = (resolution) => {
      const matches = [
        { label: 'bed bug', value: 0 },
        { label: 'other bug', value: 2 },
        { label: 'other', value: 4 }
      ];
      const { resolutionType, additionalInformation, roomId } = resolution;
      const type = _get(_find(matches, { label: resolutionType }), 'value');
      const timestamp = Math.floor(Date.now() / 1000);
      if (type !== undefined) {
        const body = {
          type,
          additionalInformation,
          roomId,
          timestamp
        };
        return postResolve(body, this.props.apiToken).then(result =>
          this.loadFeed().then(() => result));
      }
      log.warn(`invalid resolution: ${resolution}`);
    };

    loadFeed = () => {
      this.setState({ feed: { ...this.state.feed, loading: true } });
      return getFeed(this.props.apiToken)
        .then((feed) => {
          console.log(`rx feed: ${JSON.stringify(feed)}`);
          this.setState({
            feed: {
              loading: false,
              ...feed
            }
          });
        })
        .catch(this.handleApiError);
    };

    sensorQuery = (
      sensorId,
      start = Math.floor((Date.now() - TWO_WEEKS) / 1000),
      end = Math.floor(Date.now() / 1000),
    ) => {
      this.setState({
        sensorMessages: { ...this.state.sensorMessages, loading: true }
      });
      getSensorMessages(sensorId, start, end, this.props.apiToken).then((messages) => {
        this.setState({
          sensorMessages: {
            ...this.state.sensorMessages,
            [sensorId]: messages,
            loading: false
          }
        });
      });
    };

    handleApiError = (error) => {
      log.warn(error);
      this.setState({
        error
      });
    };

    loadOverview = () => {
      getRooms(this.props.apiToken)
        .then((rooms) => {
          const oldRooms = this.state.rooms;
          _forEach(rooms, (r) => {
            oldRooms[r.id] = {
              ...oldRooms[r.id],
              ...r
            };
          });
          this.setState({
            rooms: oldRooms
          });
        })
        .catch(this.handleApiError);
    };

    resetState = () => {
      this.setState({
        error: undefined,
        showDebug: true,
        feed: [],
        rooms: {},
        resolutions: {},
        sensorMessages: { loading: false },
        settings: {}
      });
    };

    loadSettings = () => {
      const mockSettings = {};

      getSettings(this.props.apiToken).then((settings) => {
        this.setState({ settings: { ...settings, ...mockSettings } }, () => {
          this.setLanguage(this.state.settings.language);
        });
      });
    };

    uploadSettings = (newSettings) => {
      putSettings(newSettings, newSettings.userId, this.props.apiToken).then((settings) => {
        this.setState({ settings });
      });
    };

    updatePassword = (currentPwd, newPwd, confirmNewPwd) => updatePassword(currentPwd, newPwd, confirmNewPwd, this.props.apiToken)

    render() {
      return (
        <ApiContext.Provider
          value={{
            ...this.state,
            loadOverview: this.loadOverview,
            loadRoomEvents: this.loadRoomEvents,
            loadFeed: this.loadFeed,
            sensorQuery: this.sensorQuery,
            loadSettings: this.loadSettings,
            uploadSettings: this.uploadSettings,
            updatePassword: this.updatePassword,
            resetState: this.resetState,
            saveResolution: this.saveResolution,
            getResolution: this.getResolution,
            localeSetting: this.state.settings.language || 'en'
          }}
        >
          {this.props.children}
        </ApiContext.Provider>
      );
    }
});
