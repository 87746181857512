import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _find from 'lodash/find';
import queryString from 'query-string';

import Modal from '../../components/Modal';
import ResolveForm from '../ResolveForm';
import { withApiConsumer } from '../../state/apiProvider';
import Resolution from '../Resolution';
import OfflineMessageView from '../OfflineMessageView';
import InactiveMessageView from '../InactiveMessageView';

const getMessages = (feed, messageId) => _find(feed, { id: messageId });

const getViewForMessage = (message, goBack) => {
  if (message.type === 'detection') {
    return <ResolveForm message={message} />;
  }
  if (message.type === 'offline' || message.type === 'debug' || message.type === 'low-battery' || message.type === 'inactive') {
    return (
      <Modal title="Leg maintenance" onClose={goBack}>
        <OfflineMessageView message={message} />
      </Modal>
    );
  }
};

const getViewForResolution = (resolutionId, closeModal) => (
  <Resolution resolutionId={resolutionId} closeModal={closeModal} />
);

const MessageModal = ({ feed, location, history }) => {
  const query = queryString.parse(location.search);
  const goBack = () => history.goBack();
  if (query.messageId) {
    const message = getMessages(feed, query.messageId);
    return message ? getViewForMessage(message, goBack) : null;
  }
  if (query.resolutionId) {
    return getViewForResolution(query.resolutionId, goBack);
  }
  return null;
};

const enhance = compose(
  withApiConsumer,
  withRouter
);

export default enhance(MessageModal);
