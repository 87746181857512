import React from 'react';
import { ToggleableButton } from '../Button';

const RadioGroup = ({
  options, onChange, selected, className, buttonClassName
}) => (
  <div className={className}>
    {(options || []).map(option => (
      <ToggleableButton
        className={buttonClassName}
        key={option}
        toggled={selected === option}
        onClick={() => onChange(option)}
        round
        style={{
          textTransform: 'capitalize'
        }}
      >
        {option}
      </ToggleableButton>
    ))}
  </div>
);

export default RadioGroup;
