import styled from 'styled-components';

import { colors } from '../../styles/variables';

const FormText = styled.p`
  margin: .4em .2em;
  padding: 0 .8em;
  line-height: 1.4em;
  color: ${colors.text2};
`;

export default FormText;
