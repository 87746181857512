import React from 'react';
import { compose, lifecycle, withState } from 'recompose';
import { MessageCircle } from 'react-feather';
import styled from 'styled-components';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';
import moment from 'moment';

import CollapsibleSection from '../../components/CollapsibleSection';
import FormText from '../../components/Form/FormText';
import { Sensor, StatusText } from '../SensorStatus';
import Button from '../../components/Button';
import Hint from '../../components/Hint';
import { colors } from '../../styles/variables';
import { withApiConsumer } from '../../state/apiProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import LocalizedSpan from '../../components/LocalizedSpan';

const StyledMessage = styled.div`
  font-size: 1.2em;
  padding: 0.8em;
  margin: 0 0.6em;
  border-top: 1px solid ${colors.itemBorderColor};

  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .action {
    font-size: 0.7em;
    margin-right: 0;
  }

  pre {
    border: 1px solid ${colors.itemBorderColor};
    font-size: 0.8em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;

  .type::first-letter {
    text-transform: uppercase;
  }

  .date {
    margin-bottom: 0.4em;
    font-size: 0.7em;
    font-weight: lighter;
    color: ${colors.text3};
  }
`;

const withCollapse = withState('collapsed', 'setCollapse', true);

const SensorMessage = withCollapse(({ message, collapsed, setCollapse }) => (
  <StyledMessage>
    <div>
      <TitleContainer>
        <span className="date">
          {moment(message.date).format('dddd, MMMM Do, HH:mm') || 'no date'}
        </span>
        <LocalizedSpan className="type" stringId={message.type} />
      </TitleContainer>
      <Button className="action" fill onClick={() => setCollapse(!collapsed)}>
        {collapsed ? 'show more' : 'hide'}
      </Button>
    </div>
    {!collapsed && <pre>{JSON.stringify(message, '', 3)}</pre>}
  </StyledMessage>
));

const LegSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .4em 1.2em;
  min-height: 4em;

  .label {
    color: ${colors.text3};
  }
`;

const LegSummary = ({ leg }) =>
  (leg ? (
    <LegSummaryContainer>
      <FormText>
        <span className="label"><LocalizedSpan stringId="CURRENT_STATUS" /></span><br />
        <StatusText status={leg.status} />
      </FormText>
      <FormText>
        <span className="label"><LocalizedSpan stringId="LAST_MESSAGE" /></span><br />
        <span>{moment(leg.lastMessage).fromNow()}</span>
      </FormText>
      <FormText>
        <span className="label"><LocalizedSpan stringId="BATTERY_LEVEL" />:</span><br />
        <span>{leg.battery}</span>
      </FormText>
    </LegSummaryContainer>
  ) : null);

const MessageList = styled.div`
  /* max-height: 30em; */
  flex: 1;
  overflow-y: scroll;
`;

const HeaderStyle = styled.div`
  position: sticky;
  background: white;
  top: 0;
  display: flex;
  padding: 0.4em 1.6em;
  justify-content: space-between;
  color: ${colors.text3};
`;

const LegInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .empty-view {
    padding: 4em;
    text-align: center;
    color: ${colors.text3};
    font-size: 1.1em;
  }
  .message-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  max-height: 40em;
  @media (max-width: 767px) {
    max-height: 80vh;
  }
`;

const LegInfo = ({ legId, sensorMessages, rooms }) => {
  const leg = _find(_flatMap(rooms, 'sensors'), { id: legId });
  const messages = _get(sensorMessages, legId, []);
  const messagesLoading = sensorMessages.loading;
  const hasMessages = messages.length > 0;
  return (
    <LegInfoContainer>
      <LegSummary leg={leg} />
      <CollapsibleSection
        title="Status messages"
        icon={<MessageCircle />}
        className="message-section top-border"
      >
        {hasMessages ?
          <MessageList
            id="leg__messageList"
          >
            <HeaderStyle>
              <span>status</span>
            </HeaderStyle>
            {messages.map(message => (
              <SensorMessage key={message.id} message={message} />
          ))}
          </MessageList> : <div id="leg__noMessages" className="empty-view">{messagesLoading ? <LoadingIndicator /> : <LocalizedSpan stringId="NO_MESSAGES_HISTORY" />}</div> }
      </CollapsibleSection>
    </LegInfoContainer>
  );
};

const withSensorQuery = compose(
  withApiConsumer,
  lifecycle({
    componentWillReceiveProps(props) {
      if (this.props.legId !== props.legId) {
        this.props.sensorQuery(props.legId);
      }
    },
    componentDidMount() {
      this.props.sensorQuery(this.props.legId);
    }
  })
);

export default withSensorQuery(LegInfo);
