import React, { createContext } from 'react';
import jwtDecode from 'jwt-decode';
import { log } from '../../utils/';

const AuthContext = createContext();

const apiUrls = {
  production: 'https://app.totemprotected.com/api/v1',
  staging: 'https://staging.totemprotected.com/api/v1',
  demo: 'https://staging.valpas.io/api/v1'
};

const API_BASE = apiUrls[process.env.API_MODE || 'staging'];
console.log(API_BASE);

export const withAuthProvider = Component => props => (
  <AuthProvider>
    <Component {...props} />
  </AuthProvider>
);

export const withAuthConsumer = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...value} {...props} />}
  </AuthContext.Consumer>
);

let tokenTimeout;
let tokenInterval;

class AuthProvider extends React.Component {
  constructor() {
    super();
    clearInterval(tokenInterval);
    clearTimeout(tokenTimeout);
    const token = localStorage.getItem('apiToken');
    if (token !== null) {
      const { exp } = jwtDecode(token);
      const timeLeft = (exp * 1000) - Date.now();
      if (timeLeft > 0) {
        console.log(`token time left ${timeLeft} ms`);
        const username = localStorage.getItem('username');
        this.state = {
          apiToken: token,
          authenticated: true,
          username
        };
        setTimeout(this.refreshToken, ((timeLeft) - 60000));
        setInterval(this.refreshToken, 600000);
      }
    } else {
      log.info('no token found');
    }
  }

  state = {
    apiToken: undefined,
    authenticated: false,
    loading: false,
    username: undefined,
    error: false
  };

  componentWillUnmount() {
    clearInterval(tokenInterval);
    clearTimeout(tokenTimeout);
  }

  handleAuthResponse = (authResponse) => {
    if (authResponse.token) {
      this.setState({
        apiToken: authResponse.token,
        authenticated: true,
        loading: false
      });
      localStorage.setItem('apiToken', authResponse.token);
      localStorage.setItem('username', this.state.username);
      return true;
    }
    throw new Error('No auth token present in response');
  };

  handleAuthError = (error) => {
    log.warn(error);
    this.setState({
      error: true,
      loading: false
    });
    return false;
  };

  refreshToken = () => {
    console.log('refresh token');
    return fetch(`${API_BASE}/refresh-token`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.apiToken}`
      }
    })
      .then(res => res.json())
      .then(this.handleAuthResponse)
      .catch(this.handleAuthError);
  }

  authenticate = (username, password) => {
    this.setState({
      username,
      loading: true
    });
    const url = `${API_BASE}/authenticate/`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(this.handleAuthResponse)
      .catch(this.handleAuthError);
  };

  logout = () => {
    localStorage.removeItem('apiToken');
    this.setState({
      apiToken: undefined,
      authenticated: false
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          authenticate: this.authenticate,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
