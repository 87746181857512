import React from 'react';
import {
  compose,
  lifecycle,
  branch,
  renderNothing,
  withStateHandlers
} from 'recompose';
import { Bell, Settings as SettingsIcon } from 'react-feather';
import styled from 'styled-components';
import Select from 'react-select';
import { tz } from 'moment-timezone';

import { withApiConsumer } from '../../state/apiProvider';
import SectionHeader from '../../components/SectionHeader';
import PageHeader from '../../components/PageHeader';
import { FormField } from '../../components/Form';
import { Input } from '../../components/TextInput';
import EmailInput from '../../components/EmailInput';
import Hint from '../../components/Hint';
import RadioButtons from '../../components/RadioButtons';
import Button from '../../components/Button';
import { colors } from '../../styles/variables';

const Container = styled.div`
  padding: 1em 2em;
  flex: 1;
  max-width: 30em;

  @media (max-width: 767px) {
    padding: 0;
    max-width: unset;
  }

  .appear {
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .panel {
    border: 1px solid ${colors.borderColor};
    border-radius: 0.4em;
    padding: 0.4em;
    text-align: center;
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .small-header {
    font-size: 0.8em;
  }

  .sections {
    flex-wrap: wrap;
  }

  .section {
    padding: 1em 0;
    margin: 0.5em 0;
    &:first-child {
      padding-top: 0;
    }
  }

  .select-dropdown {
    .css-1aya2g8 {
      padding: 0.1em 0;
      border-radius: 0.2em;
      font-size: 1em;
      border: 1px solid #a7b8ca66;
      color: #445261;
      background: #ededf1;
    }
  }
`;

const languageOptions = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Finnish',
    value: 'fi'
  }
];

const tzOptions = tz
  .names()
  .map(name => ({ value: name, label: `${name} ${tz(name).format('Z z')}` }));

const formIsValid = settings =>
  settings.timezone.length > 0;

const Settings = ({
  settings,
  localSettings,
  changeSetting,
  uploadSettings,
  resetSettings,
  localeSetting
}) => (
  <div
    style={{
      overflowY: 'scroll',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '4em'
    }}
  >
    <PageHeader titleStringId="SETTINGS_TITLE" />
    <Container>
      <div className="sections">
        <div className="section">
          <SectionHeader
            className="small-header"
            title="General"
            leftComponent={<SettingsIcon />}
          />
          <FormField label="Email address">
            <EmailInput
              localSettings={localSettings}
              localeSetting={localeSetting}
              changeSetting={changeSetting}
            />
          </FormField>
          <FormField label="Building name">
            <Input
              value={localSettings.buildingName}
              onChange={value => changeSetting('buildingName', value)}
              placeholder="Building name"
              localeSetting={localeSetting}
              type="email"
            />
          </FormField>
          <FormField label="Language">
            <Select
              value={languageOptions.find(l => l.value === localSettings.language)}
              onChange={({ value }) => changeSetting('language', value)}
              className="select-dropdown"
              options={languageOptions}
              isSearchable={false}
            />
          </FormField>
        </div>

        <div className="section">
          <SectionHeader
            className="small-header"
            title="Notifications"
            leftComponent={<Bell />}
          />
          <FormField label="Reminder interval">
            <RadioButtons
              options={['daily', 'weekly', 'never']}
              onChange={value => changeSetting('reminderInterval', value)}
              selected={localSettings.reminderInterval}
            />
          </FormField>
          <FormField label="Report interval">
            <RadioButtons
              options={['week', 'month']}
              onChange={value => changeSetting('reportInterval', value)}
              selected={localSettings.reportInterval}
            />
          </FormField>
          <FormField label="Timezone">
            <Select
              value={tzOptions.find(z => z.value === localSettings.timezone)}
              onChange={({ value }) => changeSetting('timezone', value)}
              className="select-dropdown"
              options={tzOptions}
              placeholder="Search..."
            />
          </FormField>
        </div>
      </div>

      {JSON.stringify(settings) !== JSON.stringify(localSettings) &&
        formIsValid(localSettings) && (
          <div className="appear panel">
            <FormField label="Save settings?">
              <Button fill onClick={() => uploadSettings(localSettings)}>
                save
              </Button>
              <Button onClick={resetSettings}>Cancel</Button>
            </FormField>
          </div>
        )}
    </Container>
  </div>
);

const withSettingsQuery = compose(
  withApiConsumer,
  lifecycle({
    componentDidMount() {
      this.props.loadSettings();
    }
  }),
  branch(
    props => !(props && props.settings && props.settings.userId),
    renderNothing,
    withStateHandlers(
      ({ settings }) => ({
        localSettings: settings
      }),
      {
        changeSetting: ({ localSettings }) => (key, value) => ({
          localSettings: {
            ...localSettings,
            [key]: value
          }
        }),
        resetSettings: (_, { settings }) => () => ({
          localSettings: settings
        })
      }
    )
  )
);

export default withSettingsQuery(Settings);
