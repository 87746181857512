import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { compose, withState } from 'recompose';
import { Lock, User } from 'react-feather';

import PageHeader from '../../components/PageHeader';
import SectionHeader from '../../components/SectionHeader';
import { FormField } from '../../components/Form';
import { Input } from '../../components/TextInput';
import { Button } from '../../components/Button';
import Hint from '../../components/Hint';

import { withAuthConsumer } from '../../state/authProvider';
import { withApiConsumer } from '../../state/apiProvider';
import LocalizedSpan from '../../components/LocalizedSpan';

const Container = styled.div`
  flex: 1;
  display: flex;

  padding: 1em 2em;
  max-width: 30em;

  @media (max-width: 767px) {
    padding: 0;
    max-width: unset;
  }

  .content {
    flex: 1;
  }

  .small-header {
    font-size: 0.8em;
  }

  .sections {
    flex-wrap: wrap;
  }

  .password-hint {
    margin: 0.3em;
  }

  .section {
    padding: 1em 0;
    margin: 0.5em 0;
    &:first-child {
      padding-top: 0;
    }
  }
`;

const Profile = ({
  authenticated,
  username,
  logout,
  resetState,
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  updatePassword,
  passwordChangeResult,
  setPasswordChangeResult
}) => (
  <Container>
    <div className="content">
      <PageHeader titleStringId="PROFILE_TITLE" className="header" />
      <div className="sections">
        <div className="section">
          <SectionHeader
            className="small-header"
            title={username}
            leftComponent={<User />}
          />
          <FormField>
            <Button
              fill
              id="profile__logout"
              onClick={() => {
                logout();
                resetState();
              }}
            >
              Logout
            </Button>
          </FormField>
        </div>
        {username === "demohotel" ? <div/> : (
        <div className="section">
          <SectionHeader
            className="small-header"
            title="Change password"
            leftComponent={<Lock />}
          />
          <FormField label="Current password">
            <Input
              type="password"
              value={currentPassword}
              onChange={setCurrentPassword}
            />
          </FormField>
          <FormField label="New password">
            <Input
              type="password"
              value={newPassword}
              onChange={setNewPassword}
            />
          </FormField>
          <FormField label="Confirm new password">
            <Input
              type="password"
              value={confirmNewPassword}
              onChange={setConfirmNewPassword}
              validate={value => value === '' || value === newPassword}
              invalidHint="Passwords don't match"
            />
            <Button
              fill
              disabled={(!confirmNewPassword || !newPassword) || (confirmNewPassword !== newPassword)}
              id="profile__submitPassword"
              onClick={async () => {
                const result = await updatePassword(
                  currentPassword,
                  newPassword,
                  confirmNewPassword
                );
                if (!result) {
                  setPasswordChangeResult({
                    text: 'PASSWORD_CHANGE_FAILURE',
                    type: 'error'
                  });
                } else {
                  setPasswordChangeResult({ text: 'PASSWORD_CHANGE_SUCCESS' });
                }
              }}
            >
              Confirm
            </Button>
            {passwordChangeResult && (
              <Hint
                className="password-hint"
                type={passwordChangeResult.type}
                text={<LocalizedSpan stringId={passwordChangeResult.text} />}
              />
            )}
          </FormField>
        </div>
      )}
      </div>
    </div>
    {!authenticated && <Redirect to="/" />}
  </Container>
);

const enhance = compose(
  withApiConsumer,
  withAuthConsumer,
  withState('currentPassword', 'setCurrentPassword', ''),
  withState('newPassword', 'setNewPassword', ''),
  withState('confirmNewPassword', 'setConfirmNewPassword', ''),
  withState('passwordChangeResult', 'setPasswordChangeResult', null)
);

export default enhance(Profile);
