import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withState, lifecycle } from 'recompose';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _toArray from 'lodash/toArray';
import _flatten from 'lodash/flatten';
import { Battery, Eye } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { colors } from '../../styles/variables';
import PageHeader from '../../components/PageHeader';
import Room from '../Room';
import { Input } from '../../components/TextInput';
import { withApiConsumer } from '../../state/apiProvider';
import { LinkButton } from '../../components/Button';
import LocalizedSpan from '../../components/LocalizedSpan';
import { preserveSearch } from '../../utils';
import { batteryLowLimit } from '../../views/BatterySummary/BatteryInfo';

const Container = styled.div`
  flex: 1;
  display: flex;
  background: ${colors.containerBackground};
  justify-content: center;

  .header {
    background: ${colors.containerBackground};
    z-index: 100;
    position: sticky;
    top: 0;
  }

  .content {
    flex: 1;
  }

  .filters {
    padding: 1em;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: max-content 1fr max-content max-content;
    @media (max-width: 767px) {
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      span {
        grid-column: span 2;
      }
      .spacer {
        display: none;
      }
    }

    .linkButton {
      display: flex;
      align-items: center;
      vertical-align: text-bottom;
      padding: 0.5rem;
      margin: 0;
    }
    svg {
      margin-right: 0.6rem;
    }
  }
`;

const RoomList = styled.div`
  padding: 2em;
  padding-bottom: 10em;

  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .item {
    margin: 0.5em;
  }
`;

const filteredRooms = (rooms, searchString) => {
  if (searchString) {
    const arr = _filter(rooms, r =>
      JSON.stringify({ id: r.id, name: r.name })
        .toLowerCase()
        .includes(searchString.toLowerCase()));
    return _sortBy(arr, ['status', o => parseInt(o.name, 10) || o.name]);
  }
  return _filter(
    _sortBy(rooms, ['status', o => parseInt(o.name, 10) || o.name]),
    r => r.name !== undefined && r.name !== 'Unpaired',
  );
};

const enhance = compose(
  withState('searchString', 'setSearchString', ''),
  withApiConsumer,
  withRouter,
  lifecycle({
    componentDidMount() {
      this.props.loadOverview();
    }
  }),
);

const lowBatteryCount = (rooms) => {
  const filteredSensors = _toArray(rooms)
    .filter(({ id }) => (id && id !== '0'))
    .map(({ sensors }) => sensors);
  const lowBattery = _flatten(filteredSensors)
    .filter(({ battery }) => (battery !== null) && battery < batteryLowLimit);
  return lowBattery.length;
};

const Rooms = ({
  className, rooms, searchString, setSearchString, location
}) => (
  <Container className={className}>
    <div className="content">
      <PageHeader titleStringId="ROOMS_TITLE" className="header">
        <div className="filters">
          <Input
            placeholder="Search..."
            value={searchString}
            onChange={setSearchString}
            clearable
          />
          <div className="spacer" />
          <LinkButton
            className="linkButton"
            fill
            color="white"
            alert={lowBatteryCount(rooms)}
            to={{ search: preserveSearch({ showBatterySummary: true }, location.search) }}
          >
            <Battery />
            <LocalizedSpan stringId="BATTERY_TITLE" />
            <span>&nbsp;({lowBatteryCount(rooms)})</span>
          </LinkButton>
          <LinkButton
            className="linkButton"
            fill
            color="white"
            to={{ search: 'showUnpaired=True' }}
          >
            <Eye />
            <LocalizedSpan stringId="UNPAIRED_TITLE" />
          </LinkButton>
        </div>
      </PageHeader>
      <RoomList id="rooms__list">
        {(filteredRooms(rooms, searchString) || []).map(r => (
          <Room className="item" key={r.id} room={r} />
        ))}
      </RoomList>
    </div>
  </Container>
);

export default enhance(Rooms);
