import React from 'react';
import queryString from 'query-string';

import Drawer from '../../components/Drawer';
import RoomInfo from '../RoomInfo';

const RoomDrawer = ({ location }) => {
  const query = queryString.parse(location.search);
  if (query.roomId) {
    return (
      <Drawer className="sidebar" open>
        <RoomInfo roomId={query.roomId} />
      </Drawer>
    );
  }
  return null;
};

export default RoomDrawer;
