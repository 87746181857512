import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import find from 'lodash/find';
import { compose, lifecycle, withState } from 'recompose';
import queryString from 'query-string';

import SectionHeader from '../../components/SectionHeader';
import RoomSummary from './RoomSummary';
import Legs from '../Legs';
import History from '../RoomHistory';
import { LinkButton } from '../../components/Button';
import { withApiConsumer } from '../../state/apiProvider';
import RadioGroup from '../../components/RadioButtons';
import { colors } from '../../styles/variables';
import LocalizedSpan from '../../components/LocalizedSpan';

const BackLink = ({ location }) => (
  <LinkButton
    to={{
      search: queryString.stringify({
        ...queryString.parse(location.search),
        roomId: undefined
      })
    }}
  >
    <ArrowLeft />
  </LinkButton>
);

const RoomInfoContainer = styled.div`
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .tab-selector {
    border-top: 1px solid ${colors.borderColor};
    text-align: center;
    margin-bottom: .4em;

    .tab-button {
      border-radius: .6em;
      border: 1px solid ${colors.borderColor};
      margin: 0;
      margin-top: .6em;
      min-width: 6em;

      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left-width: 0;
      }

      &:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right-width: 0;
      }
    }
  }
`;

const getRoom = (rooms, roomId) => find(rooms, { id: roomId });

export const RoomInfo = ({
  roomId,
  rooms,
  location,
  showTab,
  setVisibleTab
}) => {
  const room = getRoom(rooms, roomId);
  return room ? (
    <RoomInfoContainer>
      <SectionHeader
        title={<span><LocalizedSpan stringId="ROOM" /> {room.name}</span>}
        leftComponent={<BackLink location={location} />}
      />
      <RoomSummary status={room.status} />
      <RadioGroup
        className="tab-selector"
        buttonClassName="tab-button"
        options={['LEGS', 'HISTORY']}
        selected={showTab}
        onChange={setVisibleTab}
      />
      {showTab === 'LEGS' && <Legs legs={room.sensors} />}
      {showTab === 'HISTORY' && (
        <History loading={room.eventsLoading} events={room.events} />
      )}
    </RoomInfoContainer>
  ) : null;
};

const withTabs = withState('showTab', 'setVisibleTab', 'LEGS');

const enhance = compose(
  withApiConsumer,
  withRouter,
  withTabs,
  lifecycle({
    componentDidMount() {
      this.props.loadOverview();
      this.props.loadRoomEvents(this.props.roomId);
    },
    componentWillReceiveProps(props) {
      if (this.props.roomId !== props.roomId) {
        this.props.loadRoomEvents(props.roomId);
      }
    }
  })
);

export default enhance(({
  rooms, roomId, location, showTab, setVisibleTab
}) => (
  <RoomInfo
    rooms={rooms}
    roomId={roomId}
    location={location}
    showTab={showTab}
    setVisibleTab={setVisibleTab}
  />
));
