import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../styles/variables';

const NavLinkStyle = styled(NavLink)`
  height: 4.5em;
  display: flex;
  align-items: center;
  justify-content: start;

  .expanded {
    
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {

  }

  &.active {
    svg {
      color: ${colors.icon_active};
    }

    .icon-background {
      background: ${colors.icon_highlight};
      opacity: 0.15;
      transform: scale(1);
    }
  }

  .icon-background {
    position: absolute;
    height: 3.5em;
    width: 3.5em;
    border-radius: 10em;
    opacity: 0;
    transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(0);
  }

  svg {
    color: ${props => props.color};
    /* stroke-width: 1.3px; */
  }
`;

const NavButton = withRouter(({
  to, preserveSearch, className, icon, color, exact, location, id
}) => (
  <NavLinkStyle id={id} to={{ pathname: to, search: (preserveSearch && location && location.search) || '' }} color={color} className={className} activeClassName="active" exact={exact}>
    <div className="icon">
      <div className="icon-background" />
      {icon}
    </div>
    {/* {title && <span className="title">{title}</span>} */}
  </NavLinkStyle>
));

const ExternalNavLinkStyle = styled.a`
  height: 4.5em;
  display: flex;
  align-items: center;
  justify-content: start;

  .expanded {
    
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {

  }

  &.active {
    svg {
      color: ${colors.icon_active};
    }

    .icon-background {
      background: ${colors.icon_highlight};
      opacity: 0.15;
      transform: scale(1);
    }
  }

  .icon-background {
    position: absolute;
    height: 3.5em;
    width: 3.5em;
    border-radius: 10em;
    opacity: 0;
    transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(0);
  }

  svg {
    color: ${props => props.color};
    /* stroke-width: 1.3px; */
  }
`;

const isActive = url => window && window.location.href.includes(url);

const ExternalNavButton = withRouter(({
  to, preserveSearch, className, icon, color, exact, location, id
}) => (
  <ExternalNavLinkStyle id={id} href={to} color={color} className={`${className} ${isActive(to) ? 'active' : ''}`} activeClassName="active" exact={exact}>
    <div className="icon">
      <div className="icon-background" />
      {icon}
    </div>
    {/* {title && <span className="title">{title}</span>} */}
  </ExternalNavLinkStyle>
));


NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  preserveSearch: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  exact: PropTypes.bool
};

ExternalNavButton.propTypes = NavButton.propTypes;

NavButton.defaultProps = {
  className: '',
  preserveSearch: false,
  color: colors.icon_inactive,
  exact: true
};

ExternalNavButton.defaultProps = NavButton.defaultProps;

export default ExternalNavButton;
