import uniq from 'lodash/uniq';

export function getEventString(eventType) {
  switch (eventType) {
    case 0: return 'unquarantine';
    case 1: return 'quarantine';
    case 2: return 'resolution';
    case 3: return 'suspicion';
    default: return 'unknown event';
  }
}

export function getSensorStatusString(actionId) {
  switch (actionId) {
    case 0: return 'online';
    case 1: return 'detection';
    case 2: return 'calibration';
    case 3: return 'debug';
    case 4: return 'inactive';
    case 5: return 'tunnel';
    case 6: return 'mqtt will';
    default: return 'unknown action';
  }
}

export function getRoomStatusFromSensorStatuses(sensorStatuses) {
  const unique = uniq(sensorStatuses);
  if (unique.includes('detection')) {
    return 'detection';
  }
  if (unique.includes('out of leg') || unique.includes('offline') || unique.includes('debug') || unique.includes('mqtt will')) {
    return 'maintenance';
  }
  if (unique.includes('online')) {
    return 'online';
  }
  return 'no status';
}

export function getResolutionType(resolutionString) {
  switch (resolutionString) {
    case 'bedbug':
      return 0;
    case 'other-insect-quarantine':
      return 1;
    case 'other-insect':
      return 2;
    case 'pending':
      return 3;
    case 'other':
      return 4;
    case 'empty':
      return 5;
    default:
      return null;
  }
}

export function getResolutionString(resolutionType) {
  switch (resolutionType) {
    case 0:
      return 'bedbug';
    case 1:
      return 'other-insect-quarantine';
    case 2:
      return 'other-insect';
    case 3:
      return 'pending';
    case 4:
      return 'other';
    case 5:
      return 'empty';
    default:
      return 'unknown-resolution';
  }
}

export function getResolutionName(resolutionString) {
  switch (resolutionString) {
    case 'bedbug':
      return 'bed bug';
    case 'other-insect-quarantine':
      return 'other bug';
    case 'other-insect':
      return 'other bug';
    case 'pending':
      return 'awaiting for identification';
    case 'other':
      return 'other than bug';
    case 'empty':
      return 'empty';
    default:
      return null;
  }
}
