import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';

import { withAuthProvider, withAuthConsumer } from '../../state/authProvider';
import { withApiProvider } from '../../state/apiProvider';

import NavBar from '../../components/NavBar';
import Rooms from '../Rooms';
import Feed from '../Feed';
import Login from '../Login';
import Profile from '../Profile';
import RoomDrawer from '../RoomDrawer';
import MessageView from '../Feed/MessageView';
import LegInfo from '../LegInfo';
import Settings from '../Settings';
import Guides from '../Guides';
import BatterySummary from '../BatterySummary';
import UnpairedInfo from '../UnpairedInfo';

const Container = styled.div`
  display: flex;
  height: 100vh;

  background: white;

  /* max-height: 80em;
  max-width: 140em;
  margin: 4em auto; */

  @media (max-width: 767px) {
    height: ${window.innerHeight}px;
  }
`;

const AppStyle = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 767px) {
    justify-content: space-between;
    font-size: 0.8em;
  }

  .content {
    max-height: 100vh;
    overflow-y: scroll;
    /* flex: 1;
    display: flex;
    flex-direction: column; */
  }
`;

const LoginRedirect = withAuthConsumer(({ authenticated, location: { pathname } }) =>
  (!authenticated ? (
    <Redirect
      push
      to={{
          pathname: '/login',
          state: { referrer: pathname }
        }}
    />
  ) : pathname === '/' && (
    <Redirect
      push
      to={{
          pathname: '/feed'
        }}
    />
  )));

const App = () => (
  <Router>
    <Container>
      <Switch>
        <Route path="/login" component={Login} />
        <AppStyle>
          <NavBar className="navbar" />
          <Route path="/" component={LoginRedirect} />
          <Switch>
            <Route path="/feed" component={Feed} />
            <Route path="/rooms" component={Rooms} />
            <Route path="/settings" component={Settings} />
            <Route path="/guides" component={Guides} />
            <Route path="/profile" component={Profile} />
          </Switch>
          <Route component={RoomDrawer} />
          <Route component={MessageView} />
          <Route component={UnpairedInfo} />
          <Route component={BatterySummary} />
          <Route component={LegInfo} />
        </AppStyle>
      </Switch>
    </Container>
  </Router>
);

const enhance = compose(
  withAuthProvider,
  withApiProvider
);

export default enhance(App);
