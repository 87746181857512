import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import colors, { getStatusColor } from '../../styles/variables/colors';
import SensorStatus from '../SensorStatus';

const RoomLink = styled(NavLink)`
  position: relative;
  width: 5em;
  height: 5em;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  /* border: 1px solid ${colors.mega_faint_blueish_gray}; */
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.1); */
  border-radius: 0.6em;
  overflow: hidden;

  text-decoration: none;
  color: ${colors.text1};

  .sensors {
    z-index: 1;
    margin-top: 2em;
  }

  &.selected {
    /* box-shadow: 0 4px 6px rgba(50,50,93,.22), 0 1px 3px rgba(0,0,0,.2); */
    border-color: ${colors.faint_blueish_gray};
  }
`;

const RoomName = styled.div`
  height: 50%;
  width: 100%;
  text-align: center;
  font-size: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  z-index: 1;
`;

const ColorBackdrop = styled.div`
  position: absolute;

  ${props =>
    (props.top
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `)};
  left: 0;
  right: 0;
  background-color: ${props => props.color};
  height: 50%;
  width: 100%;
`;

const Room = ({ className, room }) => (
  room ?
    <RoomLink
      id={`room__link--${room.id}`}
      to={{ search: `roomId=${room.id}` }}
      exact
      className={classNames(className, `room__status--${room.status}`)}
      activeClassName="selected"
      title={`Room ${`${room.name}: ${room.status}`}`}
    >
      <ColorBackdrop color={getStatusColor(room.status)} top />
      <ColorBackdrop color={colors.blueish_black} />
      <RoomName>{room.name}</RoomName>
      {room.sensors && (
      <SensorStatus className="sensors" sensors={room.sensors} />
    )}
    </RoomLink> : null
);

const RoomType = PropTypes.shape({
  name: PropTypes.string,
  sensors: PropTypes.array
});

Room.propTypes = {
  room: RoomType.isRequired
};

export default Room;
