import _omitBy from 'lodash/omitBy';

import http from './http';
import { room, event, sensor, overview, feed, resolution as resolutionParser } from './parsers';

export async function getSensor(id, token) {
  let retVal;
  try {
    const rawSensor = await http.getJSON(`/sensors/${id}`, token);
    const parsedSensor = sensor.parse(rawSensor);

    retVal = parsedSensor;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getRoomEvents(roomId, token) {
  let retVal;
  try {
    const rawEvents = await http.getJSON(`/events/${roomId}`, token);
    const parsedEvents = event.parseEvents(rawEvents);

    retVal = parsedEvents;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getRoom(id, sensorIds, token) {
  let retVal;
  try {
    const rawRoom = await http.getJSON(`/rooms/${id}`, token);
    const parsedRoom = room.parse(rawRoom);

    if (sensorIds !== undefined && sensorIds.length > 0) {
      const rawSensors = await Promise.all(sensorIds.map(sensorId => http.getJSON(`/sensors/${sensorId}`, token)));
      parsedRoom.sensors = rawSensors.map(rawSensor => sensor.parse(rawSensor));
    }

    retVal = parsedRoom;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getRooms(token) {
  let retVal;
  try {
    const rawOverview = await http.getJSON('/overview/', token);
    const baseRooms = overview.parseRooms(rawOverview);

    retVal = baseRooms;

    // const roomsWithEvents = await Promise.all(baseRooms.map(async r => ({
    //   ...r,
    //   events: await getRoomEvents(r.id, token)
    // })));

    // retVal = roomsWithEvents;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getFeed(token) {
  let retVal;
  try {
    const rawFeed = await http.getJSON('/feed', token);

    retVal = feed.parse(rawFeed);
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getSensorMessages(sensorId, startDate, endDate, token) {
  let retVal;
  try {
    const requestUrl = `/sensor-query?sensorIds=${sensorId}&startDate=${startDate}&endDate=${endDate}&limit=200000000000`;
    const rawResult = await http.getJSON(requestUrl, token);

    retVal = sensor.parseQueryResult(rawResult);
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function loadResolve(resolutionId, token) {
  let retVal;
  try {
    const rawResult = await http.getJSON(`/resolutions/${resolutionId}`, token);
    const resolution = resolutionParser.parse(rawResult);
    return resolution;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function postResolve(resolution, token) {
  let retVal;
  try {
    const body = resolution;
    const rawResult = await http.requestJSON('/resolutions/', 'POST', body, token);
    return rawResult;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function getSettings(token) {
  let retVal;
  try {
    const rawResult = await http.getJSON('/settings/', token);

    retVal = rawResult;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function updatePassword(currentPassword, newPassword, confirmNewPassword, token) {
  let retVal;
  try {
    const body = { currentPassword, newPassword, confirmNewPassword };
    const rawResult = await http.requestJSON('/users/change-password/', 'PUT', body, token);

    retVal = rawResult;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}

export async function putSettings(settings, userId, token) {
  let retVal;
  try {
    const parsedSettings = _omitBy(settings, o => !o);
    const rawResult = await http.requestJSON(`/settings/${userId}`, 'PUT', parsedSettings, token);

    retVal = rawResult;
  } catch (e) {
    console.log(e);
  }
  return retVal;
}
